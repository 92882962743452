import React, { useState } from 'react';

import { Button } from '#components/button';
import { Observer } from '#components/observer';
import { Overline } from '#components/overline';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { Typography } from '#components/typography';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Statement = (props) => {
	const { text, title, color, overline, buttonLink, buttonTitle } = props;
	const [inView, setInView] = useState(false);

	const classes = classNames(
		styles.cmtSStatement,
		{
			[styles.cmtSStatementIntersect]: inView
		}
	);

	const onChange = (val) => setInView(val);

	return (
		<Observer rootMargin='0% 0% -45%' triggerOnce onChange={onChange}>
			<Slice className={classes} color={color}>
				<SliceContainer className={styles.cmtSStatement__sliceContainer} containerClassName={styles.cmtSStatement__sliceContainerInner}>
					<SliceColumn className={styles.cmtSStatement__sliceColumn} col>
						<div className={styles.cmtSStatement__container}>
							<div className={styles.cmtSStatement__header}>
								{
									overline && (
										<div className={styles.cmtSStatement__lineWrapper}>
											<div className={styles.cmtSStatement__lineContainer}>
												<div className={styles.cmtSStatement__line} />
											</div>
											<Overline className={styles.cmtSStatement__overline}>{overline}</Overline>
										</div>
									)
								}
								<Typography className={styles.cmtSStatement__title} variant={Typography.VARIANT.H4}>{title}</Typography>
							</div>
							<div className={styles.cmtSStatement__content}>
								<Typography className={styles.cmtSStatement__text} variant={Typography.VARIANT.H5} weight={Typography.WEIGHT.LIGHT}>{text}</Typography>
								{ 
									(buttonTitle && buttonLink) && (
										<div className={styles.cmtSStatement__button}>
											<Button to={buttonLink}>{buttonTitle}</Button>
										</div>
									)
								}
							</div>
						</div>
					</SliceColumn>
				</SliceContainer>
			</Slice>
		</Observer>
	);
};

export const StatementSlice = ({ slice }) => {
	const { primary } = slice;
	const color = getObjectData(primary, 'color');
	const overline = getObjectData(primary, 'overline.text');
	const title = getObjectData(primary, 'title1.text');
	const text = getObjectData(primary, 'text.text');
	const buttonLink = getObjectData(primary, 'button_link.url');
	const buttonText = getObjectData(primary, 'button_title.text');

	return (
		<Statement color={color} overline={overline} title={title} text={text} buttonLink={buttonLink} buttonTitle={buttonText} />
	);
};

export const query = graphql`
	fragment FragStatement on PrismicPageDataBodyStatement {
		primary {
			color
			overline {
				text
			}
			title1 {
				text
			}
			text {
				text
			}
			button_title {
				text
			}
			button_link {
				url
			}
		}
	}
`;