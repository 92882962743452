import { TYPE } from './constants';
import { useSiteMetadata } from '#hooks/use-site-metadata';
import React from 'react';

export const SEO = ({ title, seoTitle, seoDesciption, image, url, type, children }) => {
    const { title: defaultTitle, description: defaultDescription, image: defaultImage } = useSiteMetadata();

    const theTitle = title ? `${title} | Cometa` : `${defaultTitle} | Cometa`;
    const theSeoTitle = seoTitle ? seoTitle : defaultTitle;
    const theSeoDescription = seoDesciption ? seoDesciption : defaultDescription;
    const theImage = image ? image : defaultImage;
    const theType = type ? type : TYPE.WEBSITE;

    // og:card = 1200x630
    // normal meta image tag 800x400

	return (
        <>
            <title>{theTitle}</title>
            <meta name="title" content={theSeoTitle} />
            <meta name="description" content={theSeoDescription} />
            <meta name="image" content={theImage} />
            <meta property="og:title" content={theSeoTitle} />
            <meta property="og:description" content={theSeoDescription} />
            <meta property="og:image" content={theImage} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content={theType} />
            {children}
        </>
	);
};

SEO.TYPE = TYPE;
export default SEO;