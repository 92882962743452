import React, { useState } from 'react';

import { CardActions, CardContent, CardHeader, CardText } from '#components/card';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IconButton } from '#components/icon-button';
import { Linker } from '#components/linker';
import { Typography } from '#components/typography';
import ArrowRightIcon from '#icons/arrow-right';

import * as styles from './styles.module.scss';

export const NavMegaCard = ({ url, title, subtitle, content, image }) => {
	const [hovered, setHovered] = useState(false);
	const text = subtitle || content;

	return (
		<Linker to={url}>
			<div className={styles.cmtNavMegaCard} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
				<CardContent className={styles.cmtNavMegaCard__cardContent}>
					<CardHeader>{title}</CardHeader>
					<CardText>
						<Typography variant={Typography.VARIANT.B5}>
							{text}
						</Typography>
					</CardText>
					<CardActions>
						<IconButton variant={IconButton.VARIANT.CLEAR} selected={hovered} position={IconButton.POSITION.RIGHT} icon={<ArrowRightIcon />}>Read more</IconButton>
					</CardActions>
				</CardContent>
				<GatsbyImage
					className={styles.cmtNavMegaCard__image}
					image={image}
					alt="Splash Image"
				/>
			</div>
		</Linker>
	);
};

export default NavMegaCard;