import React from 'react';

import { Card } from '#components/card';
import { Items } from '#slices/items';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

const staticQuery = graphql`
	query {
		allPrismicPolicypage(sort: {fields:first_publication_date, order: DESC} ) {
			edges {
				node {
				  	url
					last_publication_date(formatString: "DD MMMM yyyy")
					data {
						title {
							text
					  	}
				  	}
				}
			}
	  	}
	}
`;

export const PolicyItemsSlice = (props) => {
	const { slice, ...rest } = props;
    const staticData = useStaticQuery(staticQuery);
	const { data } = useMergePrismicPreviewData(staticData);

	const { primary } = slice;
	const color = getObjectData(primary, 'color');
	const overline = getObjectData(primary, 'overline.text');
	const title = getObjectData(primary, 'title1.text');

	const items = data.allPrismicPolicypage.edges.map(x => {
		return {
			cardPadding: Card.PADDING.LARGE,
			content: `Last published: ${x.node.last_publication_date}`,
			title: x.node.data.title.text,
			link: x.node.url
		};
	});

	return (
        <Items 
			color={color}
            overline={overline}
            title={title}
            items={items}
			{...rest}
        />
    );
};

export const query = graphql`
	fragment FragPolicyitems on PrismicPageDataBodyPolicyitems {
		primary {
			color
            overline {
              text
            }
            title1 {
              text
            }
        }
	}
`;