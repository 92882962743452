import { SIZE } from './constants';
import React from 'react';
import classNames from 'classnames';

import { Typography } from '#components/typography';

import * as styles from './styles.module.scss';

export const Lockup = (props) => {
	const { className, size, overline, children, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtLockup,
		className
	);
	
	return (
        <div className={containerClasses} {...rest}>
            <Typography className={styles.cmtLockup__overline} variant={Typography.VARIANT.O1}>{overline}</Typography>
            <Typography className={styles.cmtLockup__title} variant={size === SIZE.MEDIUM ? Typography.VARIANT.H3 : Typography.VARIANT.H4}>{children}</Typography>
        </div>
	);
};

Lockup.defaultProps = {
	size: SIZE.MEDIUM
};

Lockup.SIZE = SIZE;
export default Lockup;

