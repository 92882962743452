export const PADDING = {
	NONE: 0,
	SMALL: 1,
	MEDIUM: 2,
	LARGE: 3
};

export const SIZE = {
	SMALL: 0,
	MEDIUM: 1
};
