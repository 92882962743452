import React from 'react';

import { CardHorizontalSlice } from '#slices/card-horizontal';
import { CardPopulatedSlice } from '#slices/card-populated';
import { CardStackSlice } from '#slices/card-stack';
import { ContactSlice } from '#slices/contact';
import { ContentAccordionSlice } from '#slices/content-accordion';
import { ContentImageSlice } from '#slices/content-image';
import { ContentSlice } from '#slices/content';
import { DataSlice } from '#slices/archive/data';
import { DocumentItemsSlice } from '#slices/document-items';
import { Fab, FabPageWrapper } from '#components/fab';
import { Footer } from '#components/footer';
import { GoBackSlice } from '#slices/go-back';
import { HomeSplash } from '#slices/home-splash';
import { ImageSlice } from '#slices/image';
import { Nav } from '#components/nav';
import { NavMain } from '#components/nav-main';
import { NewsItemsSlice } from '#slices/news-items';
import { ParallaxProvider } from 'react-scroll-parallax';
import { PolicyItemsSlice } from '#slices/policy-items';
import { SliceZone } from '@prismicio/react';
import { Splash } from '#slices/splash';
import { StatementSlice } from '#slices/statement';
import { UpNextSlice } from '#slices/up-next';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { ContentFeaturedSlice } from '#slices/content-featured';
import { ContentLinkSlice } from '#slices/content-link';
import { SEO } from '#components/seo';
import { getFirst, getObjectData } from '#utils/prismicParseHelpers';
import { linkResolver } from '#utils/linkResolver';

import { FormUploadSlice } from '#slices/form-upload';

import Wrapper from '../wrapper';

import './transition.scss';

const Template = (props) => {
	const { data } = props;

	const { prismicPage } = data;
	const doc = getObjectData(prismicPage, 'data');
	const tag = getFirst(getObjectData(prismicPage, 'tags'));

	const hasUpNext = doc.body.find(x => x.slice_type === 'goback');

	return (
		<ParallaxProvider>
			<Wrapper>
				<NavMain />
				<Nav />
				<main>
					<SliceZone
						slices={doc.body}
						components={{
							contentfeatured: ContentFeaturedSlice,
							contact: ContactSlice,
							content: ContentSlice,
							contentimage: ContentImageSlice,
							cardstack: CardStackSlice,
							cardpopulated: CardPopulatedSlice,
							contentaccordion: ContentAccordionSlice,
							contentlink: ContentLinkSlice,
							data: DataSlice,
							statement: StatementSlice,
							image: ImageSlice,
							formupload: FormUploadSlice,
							homesplash: HomeSplash,
							splash: (props) => <Splash pill={tag} {...props} />,
							upnext: UpNextSlice,
							goback: GoBackSlice,
							footer: Footer,
							cardhorizontal: CardHorizontalSlice,
							blogitems: NewsItemsSlice,
							documentitems: DocumentItemsSlice,
							policyitems: PolicyItemsSlice
						}}
					/>
					{
						hasUpNext && (
							<FabPageWrapper>
								<Fab />
							</FabPageWrapper>
						)
					}
				</main> 
			</Wrapper>
		</ParallaxProvider>		
	);
};

export const Head = (props) => {
	const { location, data } = props;
	const pathname = getObjectData(location, 'pathname');
	const { seo_description: seoDesciption, seo_title: seoTitle } = getObjectData(data, 'prismicPage.data');

	const primarySlice = props.data.prismicPage.data.body.filter(x => x.slice_type === 'splash')[0];
	const title = getObjectData(primarySlice, 'primary.title1.text');
	const image = getObjectData(primarySlice, 'primary.image.url');

	return (
		<SEO
			title={title}
			seoTitle={seoTitle.text}
			seoDesciption={seoDesciption.text}
			image={image}
			url={pathname}
			type={SEO.TYPE.WEBSITE}
		/>
	);
};

export const pageQuery = graphql`
	query LayoutPage($uid: String!) {
		prismicPage(uid: { eq: $uid }) {
			_previewable
			tags
			data {
				seo_description {
					text
				}
				seo_title {
					text
				}
				body {
					... on PrismicSliceType {
						slice_type
					}
					...FragCardstack
					...FragCardpopulated
					...FragContent
					...FragContentaccordion
					...FragContentimage
					...FragContentfeatured
					...FragImage
					...FragSplash
					...FragHomesplash
					...FragImage
					...FragStatement
					...FragCardhorizontal
					...FragUpnext
					...FragBlogitems
					...FragGoback
					...FragDocumentitems
					...FragPolicyitems
					...FragFooter
					...FragContentlink
					...FragContact
					...FragFormupload
				}
			}
		}
	}
`;

export default withPrismicPreview(Template,
	[
		{
			repositoryName: 'cometa-website',
			linkResolver
		}
	]
);