import { Linker } from '#components/linker';
import { SliceContainer } from '#slices/slice';
import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

const staticQuery = graphql`
    query {
        allPrismicNavigation {
            nodes {
                data {
                    main_items {
                        label {
                            text
                        }
                        link {
                            url
                        }
                    }
                }
            }
        }
    }
`;  

export const NavMain = ({ className }) => {
    const staticData = useStaticQuery(staticQuery);
	const { data } = useMergePrismicPreviewData(staticData);
    const navItems = data.allPrismicNavigation.nodes[0].data.main_items;

    const containerClasses = classNames(
        styles.cmtNavMain,
        className
    );

	return (
        <nav className={containerClasses}>
            <SliceContainer className={styles.cmtNavMain__wrapper} containerClassName={styles.cmtNavMain__container}>
                <div className={styles.cmtNavMain__items}>
                {
                    navItems.map((x, i) => {
                        const label = x.label.text;
                        const link = x.link.url;
                        return (
                            <Linker key={i} to={link} className={styles.cmtNavMain__link}>
                                <span className={styles.cmtNavMain__text}>{label}</span>
                            </Linker>
                        );
                    })
                }
                </div>
            </SliceContainer>
		</nav>
	);
};

export default NavMain;