import React from 'react';

const Icon = ({ className }) => (
    <svg className={className} width="188" height="32" viewBox="0 0 188 32" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1752_2750)">
            <path d="M188.309 31.6841H169.921V20.0364H176.609C183.076 20.0364 188.309 25.2502 188.309 31.677V31.6841Z" />
            <path d="M188.309 7.56566H169.921V19.2062H176.609C183.076 19.2062 188.309 13.9925 188.309 7.56566V7.56566Z" />
            <path d="M22.0261 13.8076L18.4826 15.9853C17.6484 13.0273 14.8464 11.4099 11.8304 11.4099C7.10333 11.4099 3.90915 14.7227 3.90915 19.7307C3.90915 24.7388 6.93221 28.0515 11.8304 28.0515C15.0246 28.0515 17.8267 26.0937 18.6181 23.5187L22.0333 25.6964C20.5004 29.1794 16.472 31.7473 11.8304 31.7473C4.73622 31.7544 -0.211914 26.9591 -0.211914 19.7307C-0.211914 12.5024 4.86455 7.71418 11.8233 7.71418C16.5504 7.71418 20.5788 10.2821 22.0261 13.8147V13.8076Z" />
            <path d="M49.7757 19.7313C49.7757 26.832 44.4354 31.755 37.5622 31.755C30.689 31.755 25.4343 26.832 25.4343 19.7313C25.4343 12.6306 30.7318 7.70766 37.5622 7.70766C44.3926 7.70766 49.7757 12.6306 49.7757 19.7313V19.7313ZM37.5622 28.0521C42.2466 28.0521 45.619 24.789 45.619 19.7313C45.619 14.6736 42.2466 11.4105 37.5622 11.4105C32.8779 11.4105 29.5483 14.7232 29.5483 19.7313C29.5483 24.7394 32.9635 28.0521 37.5622 28.0521Z" />
            <path d="M85.8597 15.8016V30.9251H81.4249V16.3691C81.4249 13.2621 79.9776 11.6944 77.0757 11.6944C74.1739 11.6944 71.7568 13.9643 71.7568 18.5539V30.9251H67.3648V16.3691C67.3648 13.2621 65.9175 11.6944 62.9728 11.6944C60.0282 11.6944 57.654 14.014 57.654 18.5539V30.9251H53.262V8.3746H57.4828V11.737C59.0229 9.15489 61.1761 7.84967 64.0281 7.84967C67.4575 7.84967 69.7819 9.24711 71.0153 12.0916C72.641 9.2542 75.0152 7.84967 78.0454 7.84967C83.0078 7.84967 85.8669 11.0418 85.8669 15.8016H85.8597Z" />
            <path d="M111.52 19.3837C111.52 19.9086 111.52 20.469 111.435 21.0791H93.5317C94.0165 25.8673 97.0753 28.2649 101.018 28.2649C104.519 28.2649 106.886 26.8249 108.198 23.8243L111.221 26.3922C109.16 30.0951 105.046 31.7479 100.933 31.7479C93.9738 31.7479 89.375 26.8249 89.375 19.8164C89.375 12.808 94.1021 7.70766 100.626 7.70766C107.15 7.70766 111.528 12.4533 111.528 19.3766L111.52 19.3837ZM107.185 17.9437C107.185 13.8082 104.562 11.2332 100.533 11.2332C97.118 11.2332 94.2304 13.496 93.6173 17.9437H107.185Z" />
            <path d="M122.857 0.0465927V8.4525H129.381V12.0277H122.857V24.4415C122.857 27.5343 124.126 28.0166 126.579 28.0166C127.192 28.0166 128.283 27.8393 128.946 27.7116V31.2371C128.247 31.4996 126.628 31.6698 125.972 31.6698C121.374 31.6698 118.836 29.8397 118.836 24.9593V12.0206H114.807V8.44541H118.836V3.05428L122.864 0.0465927H122.857Z" />
            <path d="M156.952 21.299L160.324 31.0953H156.082L153.237 22.4694L152.802 28.0024C151.048 30.1376 148.296 31.7479 144.353 31.7479C137.395 31.7479 132.839 26.4773 132.839 19.7739C132.839 13.0704 137.395 7.70766 144.353 7.70766C148.203 7.70766 151.098 9.31791 152.931 11.7581V8.4454H156.959V21.299H156.952ZM145.002 28.0947C149.865 28.0947 153.059 24.4769 153.059 19.7739C153.059 15.0708 149.865 11.368 145.002 11.368C140.14 11.368 136.945 15.0708 136.945 19.7739C136.945 24.4769 139.968 28.0947 145.002 28.0947Z" />
            <path d="M164.318 27.7398C163.334 27.7398 162.535 28.5343 162.535 29.5132C162.535 30.4921 163.334 31.2866 164.318 31.2866C165.302 31.2866 166.1 30.4921 166.1 29.5132C166.1 28.5343 165.302 27.7398 164.318 27.7398ZM164.318 31.0951C163.441 31.0951 162.728 30.3857 162.728 29.5132C162.728 28.6407 163.441 27.9314 164.318 27.9314C165.195 27.9314 165.908 28.6407 165.908 29.5132C165.908 30.3857 165.195 31.0951 164.318 31.0951Z" />
            <path d="M164.317 31.3224C163.312 31.3224 162.499 30.5137 162.499 29.5135C162.499 28.5133 163.312 27.7047 164.317 27.7047C165.322 27.7047 166.135 28.5133 166.135 29.5135C166.135 30.5137 165.322 31.3224 164.317 31.3224ZM164.317 27.7756C163.355 27.7756 162.57 28.5559 162.57 29.5135C162.57 30.4712 163.355 31.2515 164.317 31.2515C165.28 31.2515 166.064 30.4712 166.064 29.5135C166.064 28.5559 165.28 27.7756 164.317 27.7756ZM164.317 31.1309C163.426 31.1309 162.692 30.4073 162.692 29.5135C162.692 28.6197 163.419 27.8962 164.317 27.8962C165.215 27.8962 165.936 28.6197 165.936 29.5135C165.936 30.4073 165.208 31.1309 164.317 31.1309ZM164.317 27.9742C163.462 27.9742 162.763 28.6694 162.763 29.5206C162.763 30.3718 163.462 31.067 164.317 31.067C165.173 31.067 165.864 30.3718 165.864 29.5206C165.864 28.6694 165.166 27.9742 164.317 27.9742Z" />
            <path d="M163.726 28.605H163.697V30.4281H163.975V29.7258H164.282L164.724 30.4139V30.4281H165.059L165.03 30.3855L164.581 29.7046C164.688 29.6833 164.795 29.6336 164.873 29.5556C164.966 29.4634 165.023 29.3357 165.023 29.1654C165.023 28.9952 164.966 28.8533 164.859 28.754C164.752 28.6547 164.61 28.605 164.439 28.605H163.726V28.605ZM163.975 29.4776V28.8604H164.41C164.51 28.8604 164.588 28.8959 164.645 28.9455C164.702 29.0023 164.738 29.0732 164.738 29.1654C164.738 29.2577 164.702 29.3357 164.645 29.3853C164.588 29.4421 164.51 29.4705 164.41 29.4705H163.975V29.4776Z" />
            <path d="M165.13 30.4638H164.717L164.695 30.4354L164.26 29.7616H164.004V30.4638H163.654V28.5698H164.431C164.61 28.5698 164.759 28.6266 164.873 28.733C164.988 28.8394 165.052 28.9884 165.052 29.1657C165.052 29.3288 164.995 29.4707 164.895 29.5771C164.824 29.6481 164.738 29.6977 164.638 29.7261L165.123 30.4638H165.13ZM164.759 30.3929H165.002L164.524 29.6764H164.581C164.688 29.6481 164.781 29.6055 164.852 29.5275C164.945 29.4352 164.988 29.3147 164.988 29.1657C164.988 29.0096 164.938 28.8749 164.838 28.7826C164.738 28.6904 164.603 28.6408 164.446 28.6408H163.74V30.3929H163.947V29.6906H164.31V29.7048L164.759 30.3858V30.3929ZM164.417 29.5133H163.947V28.8252H164.417C164.524 28.8252 164.61 28.8607 164.681 28.9174C164.745 28.9813 164.781 29.0664 164.781 29.1657C164.781 29.265 164.745 29.3501 164.681 29.414C164.617 29.4778 164.524 29.5133 164.417 29.5133V29.5133ZM164.018 29.4423H164.417C164.503 29.4423 164.574 29.414 164.631 29.3643C164.681 29.3147 164.71 29.2437 164.71 29.1657C164.71 29.0877 164.681 29.0167 164.631 28.9671C164.581 28.9174 164.503 28.889 164.417 28.889H164.018V29.4352V29.4423Z" />
        </g>
        <defs>
            <clipPath id="clip0_1752_2750">
                <rect width="188" height="32" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon;