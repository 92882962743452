import React from 'react';

import { Divider } from '#components/divider';
import { Typography } from '#components/typography';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const CardHeader = (props) => {
	const { className, children, subtitle, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtCardHeader,
		className
	);

	return (
		<div className={containerClasses} {...rest}>
			<Typography className={styles.cmtCardHeader__title} variant={Typography.VARIANT.H6}>{children}</Typography>
			<Divider />
			{ 
				subtitle && (
					<Typography className={styles.cmtCardHeader__subtitle} variant={Typography.VARIANT.H7}>{subtitle}</Typography>
				)
			}
		</div>
	);
};

export default CardHeader;
