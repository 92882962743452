import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Accordian = (props) => {
	const { className, children, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtAccordian,
		className
	);

	return (
		<ul className={containerClasses} {...rest}>
			{children}
		</ul>
	);
};

export default Accordian;
