import React from 'react';
import classNames from 'classnames';

import { Paper } from '#components/paper';

import * as styles from './styles.module.scss';

export const CardContent = (props) => {
	const { className, children, onHover, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtCardContent,
		className
	);

	return (
		<Paper
			color={Paper.COLOR.TRANSPARENT}
			onHover={onHover}
			padding={Paper.PADDING.LARGE} 
			className={containerClasses} 
			{...rest}>
			{children}
		</Paper>
	);
};

export default CardContent;
