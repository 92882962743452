import React from 'react';

import { MODE, useMode } from '#providers/mode';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const NavItem = (props) => {
	const { children, className, ...rest } = props;
	
	const mode = useMode();

	const containerClasses = classNames(
		styles.cmtNavItem,
		{
			[styles.cmtNavItemModeDark]: mode === MODE.DARK
		},
		className
	);

	return (
		<div className={containerClasses} {...rest}>
			<button className={styles.cmtNavItem__container}>
				<span className={styles.cmtNavItem__text}>
					{children}
				</span>
			</button>
		</div>
	);
};

export default NavItem;