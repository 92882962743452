import React from 'react';

import { Overline } from '#components/overline';
import Typography from '#components/typography';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const ContentImageItem = ({ overline, title, single, shorten, children, button, first, last }) => {

    const containerClasses = classNames(
		styles.cmtSContentImageItem,
		{
			[styles.cmtSContentImageItemFirst]: first,
			[styles.cmtSContentImageItemLast]: last,
			[styles.cmtSContentImageItemSingle]: single,
			[styles.cmtSContentImageItemShorten]: shorten
		}
	);

	return (
		<div className={containerClasses}>
			<div className={styles.cmtSContentImageItem__line} />
			<div className={styles.cmtSContentImageItem__content}>
				<div className={styles.cmtSContentImageItem__contentContainer}>
					{ overline && <Overline>{overline}</Overline> }
					<Typography className={styles.cmtSContentImageItem__title} variant={first ? Typography.VARIANT.H3 : Typography.VARIANT.H4 }>{title}</Typography>
					<Typography className={styles.cmtSContentImageItem__text} variant={Typography.VARIANT.B1}>
						{children}
					</Typography>
					{	
						button && (
							<div className={styles.cmtSContentImageItem__button}>{button}</div>
						)
					}
				</div>
			</div>
		</div>
	);
};