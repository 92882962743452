import React from 'react';

import { Content } from '#slices/content';
import { Footer } from '#components/footer';
import { GoBack } from '#slices/go-back';
import { Nav } from '#components/nav';
import { NavMain } from '#components/nav-main';
import { ParallaxProvider } from 'react-scroll-parallax';
import { SimpleSplash } from '#slices/simple-splash';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import { linkResolver } from '#utils/linkResolver';
import { navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Wrapper from '../wrapper';

const Template = ({ data }) => {
	const content = getObjectData(data, 'prismicPolicypage.data.content.richText');
	const title = getObjectData(data, 'prismicPolicypage.data.title.text');
	const lastDate = getObjectData(data, 'prismicPolicypage.last_publication_date');

	return (
		<ParallaxProvider>
			<Wrapper>
				<NavMain />
				<Nav mode={Nav.MODE.DARK} />
				<SimpleSplash date={`Last published: ${lastDate}`} category='Policy' title={title} />
				<Content type={Content.TYPE.POLICY}>{content}</Content>
				<GoBack isStatic onClick={() => navigate('/policies')}>Back to Policies</GoBack>
				<Footer />
			</Wrapper>
		</ParallaxProvider>
	);
};

export const query = graphql`
	query LayoutPolicy($uid: String!) {
		prismicPolicypage(uid: { eq: $uid }) {
			last_publication_date(formatString: "DD MMMM yyyy")
      		data {
        		title {
		        	text
        		}
        		content {
          			richText
        		}
			}
		}
	}
`;

export default withPrismicPreview(Template,
	[
		{
			repositoryName: 'cometa-website',
			linkResolver
		}
	]
);