import { Provider } from './store';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const TabGroup = (props) => {
	const {
		className,
		children,
		defaultSelected,
		controlled,
		onChange,
		value,
		scrollVisible,
		...rest
	} = props;
	const [selected, setSelected] = useState(defaultSelected);
	const [isScrollable, setIsScrollable] = useState(false);
	const [isScrollLeftVisible, setIsScrollLeftVisible] = useState(false);
	const [isScrollRightVisible, setIsScrollRightVisible] = useState(false);
	const [init] = useState({
		first: false,
		current: false
	});
	const [inner, setInner] = useState();
	const containerRef = createRef();

	useEffect(() => {
		if (controlled) {
			setSelected(value);
		}
	}, [controlled, value]);

	const containerClasses = classNames(
		styles.cmtTabGroup,
		className
	);

	const handleSideButtonsShow = useCallback(() => {
		if (containerRef.current) {
			const rect = containerRef.current.getBoundingClientRect();

			const containerStart = rect.left;
			const containerEnd = rect.right;
			const firstChild = containerRef.current.firstElementChild;
			const lastChild = containerRef.current.lastElementChild;

			if (
				containerStart <= Math.floor(firstChild.getBoundingClientRect().left)
			) {
				setIsScrollLeftVisible(false);
			} else {
				setIsScrollLeftVisible(true);
			}

			if (containerEnd >= Math.floor(lastChild.getBoundingClientRect().right)) {
				setIsScrollRightVisible(false);
			} else {
				setIsScrollRightVisible(true);
			}
		}
	}, [containerRef]);

	const setScrollable = useCallback(() => {
		if (containerRef.current) {
			const { clientWidth, scrollWidth } = containerRef.current;
			setIsScrollable(scrollWidth > clientWidth);
		}
	}, [containerRef]);

    useEffect(() => {
		setScrollable();
		handleSideButtonsShow();
	}, []);

	// useResizeEffect(setScrollable, [setScrollable, containerRef]);

	const handleScrollClick = useCallback(
		value => {
			containerRef.current.scrollLeft += value;
		},
		[containerRef]
	);

	if (containerRef.current) {
		const { clientWidth, scrollWidth } = containerRef.current;
		setIsScrollable(scrollWidth > clientWidth);
	}

	const handleChange = name => {
		setSelected(name);
		onChange && onChange(name);
	};

	const isSelected = name => {
		if (!init.first) {
			init.first = true;
			return true;
		}
		return name === selected;
	};

	const getInner = useCallback(
		(name, children) => {
			if ((!init.current || name === selected) && children !== inner) {
				init.current = true;
				setInner(children);
				return true;
			}
			return false;
		},
		[init, inner, selected]
	);

	const leftScroll = useCallback(() => handleScrollClick(-100), [handleScrollClick]);
	const rightScroll = useCallback(() => handleScrollClick(100), [handleScrollClick]);

	return (
        <Provider value={{ onChange: handleChange, isSelected, getInner }}>
            <div className={containerClasses} {...rest}>
                {isScrollable && (
                    <>
                        {isScrollLeftVisible && scrollVisible && (
                            <div
                                className={styles.cmtTabGroup__overflowLeft}
                                onClick={leftScroll}>
                                <div className={styles.cmtTabGroup__overflowIconContainer}>
                                    {/* <Icon
                                        className={styles.cmtTabGroup__overflowIcon}
                                        icon='chevron-left'
                                    /> */}
                                </div>
                                <div className={styles.cmtTabGroup__overflowLeftAppearance} />
                            </div>
                        )}
                        {isScrollRightVisible && scrollVisible && (
                            <div
                                className={styles.cmtTabGroup__overflowRight}
                                onClick={rightScroll}>
                                <div className={styles.cmtTabGroup__overflowRightAppearance} />
                                <div className={styles.cmtTabGroup__overflowIconContainer}>
                                    {/* <Icon
                                        className={styles.cmtTabGroup__overflowIcon}
                                        icon='chevron-right'
                                    /> */}
                                </div>
                            </div>
                        )}
                    </>
                )}
                <ul
                    ref={containerRef}
                    onScroll={() => handleSideButtonsShow()}
                    className={styles.cmtTabGroup__container}
                    role='tablist'>
                    {children}
                </ul>
            </div>
        </Provider>
	);
};

TabGroup.defaultProps = {
	scrollVisible: true
};

export default TabGroup;