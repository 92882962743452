import React, { useState } from 'react';

import { Linker } from '#components/linker';
import ButtonIcon from '#icons/button';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Button = (props) => {
	const { children, to, disabled, className,  ...rest } = props;
	const [hover, setHover] = useState(false);

	const containerClasses = classNames(
		styles.cmtButton,
		{
			[styles.cmtButtonHovered]: hover,
			[styles.cmtButtonDisabled]: disabled
		},
		className
	);

	return (
		<Linker contain to={to} className={styles.cmtButton__linker}>
			<button className={containerClasses}
				aria-label='Button'
				disabled={disabled}
				tabIndex={disabled ? 0 : -1}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				{...rest}
			>
				<div className={styles.cmtButton__container}>
					<div className={styles.cmtButton__wave} />
					<span className={styles.cmtButton__text}>{children}</span>
					<ButtonIcon className={styles.cmtButton__icon} />
				</div>
			</button>
		</Linker>
	);
};

export default Button;