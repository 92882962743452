import React from 'react';

import { COLOR, POSITION } from './constants';
import { ContentProvider } from '#providers/content';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LinkCard } from '#components/link-card';
import { Lockup } from '#components/lockup';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { Typography } from '#components/typography';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import LocationIcon from '#icons/location';
import MailIcon from '#icons/mail';
import MobileIcon from '#icons/mobile';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Contact = props => {
	const { position, color, image, children, overline, title, className, addressLine1, addressLine2 } = props;

	const classes = classNames(
		styles.cmtSContact, 
		{
			[styles.cmtSContactPositionReverse]: position === POSITION.REVERSE
		},
		className
	);

	return (
		<ContentProvider>
			<Slice className={classes} color={color}>
				<SliceContainer containerClassName={styles.cmtSContact__sliceContainerInner}>
					<SliceColumn>
						<div className={styles.cmtSContact__imageWrapper}>
							<GatsbyImage
								image={image}
								className={styles.cmtSContact__image}
								alt="Contact Place Image"
							/>
						</div>
					</SliceColumn>
					<SliceColumn>
						<div className={styles.cmtSContact__content}>
							<Lockup overline={overline}>{title}</Lockup>
							<div className={styles.cmtSContact__address}>
								<Typography variant={Typography.VARIANT.B1} weight={Typography.WEIGHT.LIGHT}>{addressLine1}</Typography>
								<Typography variant={Typography.VARIANT.B1} weight={Typography.WEIGHT.LIGHT}>{addressLine2}</Typography>
							</div>
							<div className={styles.cmtSContact__download}>{children}</div>
						</div>
					</SliceColumn>
				</SliceContainer>
			</Slice>
		</ContentProvider>
	);
};

export const ContactSlice = ({ slice }) => {
	const { primary } = slice;
	
	const color = getObjectData(primary, 'color');
	const title = getObjectData(primary, 'title.text');
	const overline = getObjectData(primary, 'overline.text');
	const addressLine1 = getObjectData(primary, 'address_line_1.text');
	const addressLine2 = getObjectData(primary, 'address_line_2.text');
	const position = getObjectData(primary, 'position');
	const email = getObjectData(primary, 'email.url');
	const image = getImage(getObjectData(primary, 'image.gatsbyImageData'));
	const telephone = getObjectData(primary, 'telephone.url');
	const map = getObjectData(primary, 'map.url');

	const cardColor = color === COLOR.PRIMARY ? COLOR.SECONDARY : COLOR.PRIMARY;
	
	const formatTelephone = () => {
		return telephone.slice(4, 7) + ' ' + telephone.slice(7, 9) + ' ' + telephone.slice(9, 12) + ' ' + telephone.slice(12);
	};
	
	return (
		<Contact 
			position={position} 
			color={color} 
			image={image} 
			overline={overline} 
			title={title}
			email={email}
			telephone={telephone}
			addressLine1={addressLine1}
			addressLine2={addressLine2}
		>
			{ telephone && <LinkCard to={telephone} color={cardColor} icon={<MobileIcon />}>Call us {formatTelephone()}</LinkCard> }
			{ email && <LinkCard to={email} color={cardColor} icon={<MailIcon />}>Email our office</LinkCard> }
			{ map && <LinkCard to={map} color={cardColor} icon={<LocationIcon />}>Get directions</LinkCard> }
		</Contact>
	);
};

export const query = graphql`
	fragment FragContact on PrismicPageDataBodyContact {
		id
		primary {
			color
			position
			overline {
				text
			}
			title {
				text
			}
			address_line_1 {
			  text
			}
			address_line_2 {
			  text
			}
			email {
			  url
			}
			map {
				url
			}
			image {
				gatsbyImageData(
					placeholder: BLURRED
					width: 900
					height: 600
				)
			}
			telephone {
			  url
			}
		}
	}
`;