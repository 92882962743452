import React from 'react';

import { Card } from '#components/card';
import { Items } from '#slices/items';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

const staticQuery = graphql`
	query {
		allPrismicDocument(sort: {fields:first_publication_date, order: DESC} ) {
            edges {
				node {
					first_publication_date(formatString: "DD MMMM yyyy")
					tags
					data {
						title {
							text
			  			}
						document {
							url
							size
			  			}
					}
		  		}
			}
	  	}
	}
`;

const convertBytesToMb = (bytes) => {
	return bytes / (1024 * 1024);
};

export const DocumentItemsSlice = (props) => {
	const { slice, ...rest } = props;
	const { primary } = slice;
    const staticData = useStaticQuery(staticQuery);
	const { data } = useMergePrismicPreviewData(staticData);

	const title = getObjectData(primary, 'title1.text');
	const overline = getObjectData(primary, 'overline.text');
	const color = getObjectData(primary, 'color');

	const items = data.allPrismicDocument.edges.map(x => {
		const mb = convertBytesToMb(x.node.data.document.size);

		return {
			cardPadding: Card.PADDING.LARGE,
			link: x.node.data.document.url,
			title: x.node.data.title.text,
			subtitle: `${mb.toFixed(2)} MB`,
			content: `Published: ${x.node.first_publication_date}`,
			pill: x.node.tags[0]
		};
	});

	const tags = items.map(x => {
		return x.pill;
	});

	const unqiueTags = [...new Set(tags)];

	return (
        <Items
			color={color}
            overline={overline}
            title={title}
            items={items}
			tags={unqiueTags}
			{...rest}
        />
    );
};

export const query = graphql`
	fragment FragDocumentitems on PrismicPageDataBodyDocumentitems {
		primary {
			color
            overline {
              text
            }
            title1 {
              text
            }
        }
	}
`;