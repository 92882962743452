import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

import CollapseIcon from '#icons/collapse';

export const DrawerAccordian = (props) => {
	const {
		className,
		children,
		open,
		icon,
		title,
		...rest
	} = props;

	const [active, setActive] = useState(false);

	useEffect(() => {
		const active = open !== undefined;
		setActive(active);
	}, [open]);

	const containerClasses = classNames(
		styles.cmtDrawerAccordian,
		{
			[styles.cmtDrawerAccordianActive]: active === true
		},
		className
	);

	const onClick = useCallback(() => setActive(!active), [active]);

	return (
		<li className={containerClasses} {...rest}>
			<button
				className={styles.cmtDrawerAccordian__heading}
				tabIndex={0}
				type='button'
				onClick={onClick}
				aria-expanded={active.toString}
				aria-controls={title}
			>
				<div className={styles.cmtDrawerAccordian__iconWrapper}>{icon}</div>
				<div className={styles.cmtDrawerAccordian__titleWrapper}>
					<div className={styles.cmtDrawerAccordian__titleContainer}>
						<span className={styles.cmtDrawerAccordian__title}>{title}</span>
					</div>
					<div className={styles.cmtDrawerAccordian__chevronHolder}>
						<div className={styles.cmtDrawerAccordian__chevron}><CollapseIcon /></div>
					</div>
				</div>
			</button>
			<ul id={title} className={styles.cmtDrawerAccordian__content}>
				{children}
			</ul>
		</li>
	);
};

export default DrawerAccordian;
