import * as styles from './styles.module.scss';
import React from 'react';
import classNames from 'classnames';

export const Divider = (props) => {
	const { className, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtDivider,
		className
	);

	return <hr className={containerClasses} {...rest} />;
};

export default Divider;