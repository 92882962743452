// extracted by mini-css-extract-plugin
export var cmtSStatement = "styles-module--cmt-s-statement--9182d";
export var cmtSStatementIntersect = "styles-module--cmt-s-statement--intersect--3ab84";
export var cmtSStatement__button = "styles-module--cmt-s-statement__button--cf94f";
export var cmtSStatement__container = "styles-module--cmt-s-statement__container--08401";
export var cmtSStatement__content = "styles-module--cmt-s-statement__content--c2f30";
export var cmtSStatement__header = "styles-module--cmt-s-statement__header--ff7fa";
export var cmtSStatement__line = "styles-module--cmt-s-statement__line--15d98";
export var cmtSStatement__lineContainer = "styles-module--cmt-s-statement__line-container--c77b8";
export var cmtSStatement__lineWrapper = "styles-module--cmt-s-statement__line-wrapper--1566d";
export var cmtSStatement__overline = "styles-module--cmt-s-statement__overline--957ef";
export var cmtSStatement__overlineContainer = "styles-module--cmt-s-statement__overline-container--f9d4a";
export var cmtSStatement__sliceColumn = "styles-module--cmt-s-statement__slice-column--717a5";
export var cmtSStatement__sliceContainer = "styles-module--cmt-s-statement__slice-container--d725b";
export var cmtSStatement__sliceContainerInner = "styles-module--cmt-s-statement__slice-container-inner--bacfa";
export var cmtSStatement__text = "styles-module--cmt-s-statement__text--8fe21";
export var cmtSStatement__title = "styles-module--cmt-s-statement__title--6913b";