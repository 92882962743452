import React from 'react';
import classNames from 'classnames';

import { Pill } from '#components/pill';

import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from './styles.module.scss';

export const CardMedia = (props) => {
	const { className, image, label, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtCardMedia,
		className
	);

	return (
		<div className={containerClasses} {...rest}>
			{label && <Pill className={styles.cmtCardMedia__pill} variant={Pill.VARIANT.TERTIARY} size={Pill.SIZE.SMALL}>{label}</Pill> }
				<GatsbyImage
					className={styles.cmtCardMedia__image}
					image={image}
					alt="Card Image"
				/>
		</div>
	);
};

export default CardMedia;
