import { VARIANT } from './constants';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { GlobalContext } from '#providers/global';
import { Observer } from '#components/observer';
import { Pill } from '#components/pill';
import { Pointer } from '#components/pointer';
import { Slice, SliceContainer } from '#slices/slice';
import { getFirst, getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import { motion, useAnimation } from 'framer-motion';
import { navigate } from 'gatsby';
import { throttle } from '#utils/throttle';
import anime from 'animejs';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

const EASING_FUNC = [0.215, 0.61, 0.355, 1];

export const UpNext = (props) => {
	const { variant, category, link, image, title, className, animate, ...rest } = props;
	const imageRef = useRef(null);
	const containerRef = useRef(null);
	const newRef = useRef(null);
	const [intersecting, setIntersecting] = useState(false);
	const [mouseEntered, setMouseEntered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const { setShowBackdrop } = useContext(GlobalContext);

	useEffect(() => {
		// eslint-disable-next-line no-undef
		___loader.enqueue(link);
	}, []);

	useEffect(() => {
		if (containerRef && containerRef.current && newRef && newRef.current) {
			const rect = containerRef.current.getBoundingClientRect();
			const x = rect.left + rect.width / 2 - 40;
			const y = rect.height / 2;

			newRef.current.style.transform = `translateX(${x}px) translateY(${y}px) scale(0)`;
		}
	}, []);

	const onChange = isIntersecting => setIntersecting(isIntersecting);
	
	let scaleTimer;
	const handleMouseMove = (event) => {
		if (!isOpen) {
			if (containerRef && containerRef.current) {
				const rect = containerRef.current.getBoundingClientRect();
				const x = event.clientX - rect.left;
				const y = event.clientY - rect.top;
				const cx = rect.width / 2;
				const cy = rect.height / 2;
				const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
				let scale = Math.max(1 - distance / Math.min(cx, cy), 0.9);
	
				if (mouseEntered) {
					clearTimeout(scaleTimer);
					scaleTimer = setTimeout(() => {
						anime({
							targets: newRef.current,
							scale: 1,
							duration: 1000,
							easing: 'easeOutExpo'
						});
					}, 400);
		
					anime({
						targets: newRef.current,
						translateX: x,
						translateY: y,
						scale,
						duration: 600,
						easing: 'easeOutExpo'
					});
				}
			}
		}
	};
	

	const handleThrottledMouseMove = throttle((e) => handleMouseMove(e), 5);
	const controls = useAnimation();

	const animateSlice = () => {
		controls.start({
			zIndex: 2000
		}),
		controls.start({
			translateY: -imageRef.current.getBoundingClientRect().top,
			height: '85vh',
			transition: {
				delay: 0.1,
				translateY: {
					duration: 0.6,
					ease: EASING_FUNC.cubicInOut
				},
				height: {
					duration: 0.6,
					ease: EASING_FUNC.cubicInOut
				},
				scale: {
					duration: 0,
					ease: EASING_FUNC.cubicInOut
				}
			}
		})
		.then(() => {
			setTimeout(() => navigate(link), 300);
		});
	};

	const classes = classNames(
		styles.cmtSUpNext,
		{
			[styles.cmtSUpNextShow]: intersecting,
			[styles.cmtSUpNextClicked]: isOpen,
			[styles.cmtSUpNextVariantArticle]: variant === VARIANT.ARTICLE
		},
		className
	);

	const pointerCondition = () => {
		if (!isOpen) {
			if (mouseEntered) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	return (
		<Observer triggerOnce rootMargin='0% 0% -45%' onChange={onChange}>
			<Slice className={classes} containerClassName={styles.cmtSUpNext__sliceInner} {...rest}>
				<>
					<Pointer show={pointerCondition()} ref={newRef} />
						<motion.div
							ref={imageRef}
							className={styles.cmtSUpNext__imageContainer}
							onMouseEnter={() => setMouseEntered(true)}
							onMouseLeave={() => setMouseEntered(false)}
							onMouseMove={(e) => handleThrottledMouseMove(e)}
							initial={{ width: '100%', height: '100%', scale: 1, opacity: 1 }}
							animate={controls}
							onClick={() => {
								if (animate && !isOpen) {
									setIsOpen(true);
									setTimeout(() => {
										setShowBackdrop(true);
										animateSlice();
									}, 200);
								} else {
									navigate(link);
								}
							}}
						>
							<GatsbyImage
								className={styles.cmtSUpNext__image}
								draggable={false}
								image={image}
								alt="Card Image"
							/>
						</motion.div>
					<SliceContainer className={styles.cmtSUpNext__sliceContainer} ref={containerRef}>
						<div className={styles.cmtSUpNext__content}>
							<div className={styles.cmtSUpNext__overlineWrapper}>
								<span className={styles.cmtSUpNext__overline}>UP NEXT</span>
							</div>
							<div className={styles.cmtSUpNext__titleContainer} style={{ maxWidth: 710 }}>
								{ category && <div><Pill>{category}</Pill></div> }
								<span className={styles.cmtSUpNext__title}>{title}</span>
							</div>
						</div>
					</SliceContainer>
				</>
			</Slice>
		</Observer>
	);
};

UpNext.VARIANT = VARIANT;
UpNext.defaultProps = {
	animate: true
};

export const UpNextSlice = ({ slice }) => {
	const { primary } = slice;
	const category = getFirst(getObjectData(primary, 'link.document.tags'));
	const link = getObjectData(primary, 'link.url');
	const document = getFirst(getObjectData(primary, 'link.document.data.body'));
	const image = getObjectData(document, 'primary.image.gatsbyImageData');
	const title = getObjectData(document, 'primary.title1.text');

	return <UpNext category={category} link={link} image={image} title={title} />;
};

export const query = graphql`
	fragment FragUpnext on PrismicPageDataBodyUpnext {
		... on PrismicPageDataBodyUpnext {
			primary {
				link {
					url
					document {
						... on PrismicPage {
							tags
							data {
								body {
									... on PrismicPageDataBodySplash {
										primary {
											image {
												gatsbyImageData(
													placeholder: BLURRED
												)
											}
											title1 {
												text
											}
										}
									}
								}						
							}
						}
					}
				}
			}
		}
	}
`;
