import React from 'react';

const Icon = ({ className }) => (
    <svg className={className} width="16" height="147" viewBox="0 0 16 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 93L8 0.999998" stroke="url(#paint0_linear_2279_6432)" strokeWidth="2" strokeLinecap="round" strokeDasharray="12 12"/>
        <path d="M7.29289 146.707C7.68342 147.098 8.31658 147.098 8.70711 146.707L15.0711 140.343C15.4616 139.953 15.4616 139.319 15.0711 138.929C14.6805 138.538 14.0474 138.538 13.6569 138.929L8 144.586L2.34315 138.929C1.95262 138.538 1.31946 138.538 0.928932 138.929C0.538408 139.319 0.538408 139.953 0.928932 140.343L7.29289 146.707ZM9 107C9 106.448 8.55228 106 8 106C7.44771 106 7 106.448 7 107L9 107ZM9 146L9 107L7 107L7 146L9 146Z" fill="#F8B924"/>
        <defs>
            <linearGradient id="paint0_linear_2279_6432" x1="9" y1="89.4895" x2="9.00002" y2="30.4763" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F8B924"/>
                <stop offset="1" stopColor="#F8B924" stopOpacity="0"/>
            </linearGradient>
        </defs>
    </svg>
);

export default Icon;


