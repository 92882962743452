import React from 'react';

import { Backdrop } from '#components/backdrop';
import { Paper } from '#components/paper';

import './styles.scss';

export default ({ children }) => {
	return (
		<div>
			<Backdrop />
			<Paper color={Paper.COLOR.LIGHT} style={{ postion: 'relative' }}>{children}</Paper>
		</div>
	);
};
