import React from 'react';

import { Hamburger } from '#components/hamburger';
import { Linker } from '#components/linker';
import { MODE, useMode } from '#providers/mode';
import LogoIcon from '#icons/logo';
import Lottie from 'lottie-react';
import classNames from 'classnames';
import reavealAnimation from '#lottie/reveal-black.json';

import * as styles from './styles.module.scss';

export const NavMenu = (props) => {
    const { open, children, onNavMouseOver, onNavMouseOut, onHamburger, minimal, fixedNav, expanded, ...rest } = props;
    const mode = useMode();

	const containerClasses = classNames(
		styles.cmtNavMenu,
		{
			[styles.cmtNavMenuFixedNav]: fixedNav,
            [styles.cmtNavMenuExpanded]: expanded,
            [styles.cmtNavMenuDark]: mode === MODE.DARK
		}
	);

	return (
        <div className={containerClasses} {...rest}>
            <Linker to='/' className={styles.cmtNavMenu__link}>
                { 
                    minimal ? (
                        <Lottie className={styles.cmtNavMenu__mark} animationData={reavealAnimation} loop={false} />
                    ) : (
                        <LogoIcon className={styles.cmtNavMenu__logo} />
                    )
                }
            </Linker>
            <div className={styles.cmtNavMenu__bar}>
                <div className={styles.cmtNavMenu__hamburger}>
                    <Hamburger open={open} fixedNav={fixedNav} onClick={onHamburger} />
                </div>
                <div className={styles.cmtNavMenu__items} onMouseOver={onNavMouseOver} onMouseOut={onNavMouseOut}>
                    {children}
                </div>
            </div>
        </div>
	);
};

export default NavMenu;