import React from 'react';

const Icon = ({ className }) => (
    <svg className={className} width="31" height="40" viewBox="0 0 31 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.4946 40.0002H0V20.6836H11.0911C21.8157 20.6836 30.4946 29.3302 30.4946 39.9884V40.0002Z" fill="#212327"/>
        <path d="M30.4946 0H0V19.3048H11.0911C21.8157 19.3048 30.4946 10.6582 30.4946 0Z" fill="#212327"/>
    </svg>
);

export default Icon;
