import React, { useCallback, useContext } from 'react';

import { GlobalContext } from '#providers/global';
import { navigate } from 'gatsby';
import ArrowLeftIcon from '#icons/arrow-left';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Fab = (props) => {
    const { className, ...rest } = props;
    const { showFab, fabLink } = useContext(GlobalContext);

	const classes = classNames(
		styles.cmtFab,
        {
            [styles.cmtFabVisible]: showFab,
            [styles.cmtFabHidden]: !showFab
        },
		className
	);

    const handledClick = useCallback(() => navigate(fabLink));

	return (
        <div className={classes} {...rest}>
            <button
                aria-label='Menu button'
                className={styles.cmtFab__button}
                tabIndex={0}
                type='button'
                onClick={handledClick}
                {...rest}>
                <span className={styles.cmtFab__buttonContainer}>
                    <ArrowLeftIcon className={styles.cmtFab__icon} />
                </span>
            </button>
        </div>
	);
};

export default Fab;