import React from 'react';

const Icon = ({ className }) => (
    <svg className={className} fill='#000' width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2179_4650)">
            <path d="M6.66699 9.33398V10.7923L15.3337 16.0007L24.0003 10.7923V9.33398H6.66699ZM6.66699 12.4606V22.6673H24.0003V12.4606L15.3337 17.6673L6.66699 12.4606Z" />
        </g>
        <defs>
            <clipPath id="clip0_2179_4650">
                <rect width="32" height="32" rx="4" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon;
