import React from 'react';

import * as styles from './styles.module.scss';

import classNames from 'classnames';

export const NavMega = (props) => {
    const { open, children, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtNavMega,
		{
			[styles.cmtNavMegaOpen]: open,
			[styles.cmtNavMegaClosed]: !open
		}
	);	

	return (
        <div className={containerClasses} {...rest}>
            <div className={styles.cmtNavMega__container}>
                {children}
            </div>
        </div>
	);
};

export default NavMega;