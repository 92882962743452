import { Typography } from '#components/typography';
import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Overline = (props) => {
	const { children, className, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtOverline,
		className
	);

	return (
		<div className={containerClasses} {...rest}>
			<Typography variant={Typography.VARIANT.O1}>{children}</Typography>
		</div>
	);
};

export default Overline;
