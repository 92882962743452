import { IdProvider } from './id';
import GlobalProvider from './global';
import React from 'react';

export default ({ children }) => (
	<IdProvider>
		<GlobalProvider>
			{children}
		</GlobalProvider>
	</IdProvider>
);