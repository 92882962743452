import React from 'react';

import { Accordian, AccordianItem } from '#components/accordian';
import { Lockup } from '#components/lockup';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { PrismicRichText } from '@prismicio/react';

import * as styles from './styles.module.scss';

export const ContentAccordion = ({ color, overline, title, children, className }) => {
	const containerClasses = classNames(
		styles.cmtContentAccordion,
		className
	);

	return (
		<Slice color={color}>
			<SliceContainer>
				<SliceColumn col>
					<Lockup overline={overline}>{title}</Lockup>
					<div className={containerClasses}>
						<div className={styles.cmtContentAccordion__container}>
							{children}
						</div>
					</div>
				</SliceColumn>
			</SliceContainer>
		</Slice>
	);
};

export const ContentAccordionSlice = ({ slice }) => {
	const { primary, items } = slice;
	const color = getObjectData(primary, 'color');
	const overline = getObjectData(primary, 'overline.text');
	const title = getObjectData(primary, 'title1.text');

	return (
		<ContentAccordion color={color} overline={overline} title={title}>
			<Accordian>
				{
					items.map((x, i) => {
						const title = getObjectData(x, 'title1.text');
						const text = getObjectData(x, 'text.richText');
						return <AccordianItem key={i} title={title}><PrismicRichText field={text} /></AccordianItem>;
					})
				}
			</Accordian>
		</ContentAccordion>
	);
};

export const query = graphql`
	fragment FragContentaccordion on PrismicPageDataBodyContentaccordion {
		primary {
			color
			overline {
				text
			}
			title1 {
				text
			}
		}
		items {
			text {
				richText
			}
			title1 {
				text
			}
		}
	}
`;