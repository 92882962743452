import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
        query {
            prismicSeo {
                data {
                    title {
                        text
                    }
                    description {
                        text
                    }
                    image {
                        url
                    }
                }
            }
        }
  `);
  
    const title = data.prismicSeo.data.title.text;
    const description = data.prismicSeo.data.description.text;
    const image = data.prismicSeo.data.image.url;

    return { title, description, image };
};