import React from 'react';

import { Items } from '#slices/items';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import { getImage } from 'gatsby-plugin-image';

const staticQuery = graphql`
	query {
		allPrismicBlogpage(sort: {fields:first_publication_date, order: DESC} ) {
	  		edges {
				node {
					url
					first_publication_date(formatString: "DD MMMM yyyy")
		  			data {
						content {
							text
						}
						title {
			  				text
						}
						image {
							gatsbyImageData(
								placeholder: BLURRED
								width: 500
								height: 500
							)
						}
		  			}
		  			tags
				}
			}
	  	}
	}
`;

export const NewsItemsSlice = (props) => {
	const { slice, ...rest } = props;
    const staticData = useStaticQuery(staticQuery);
	const { data } = useMergePrismicPreviewData(staticData);

	const { primary } = slice;
	const overline = getObjectData(primary, 'overline.text');
	const title = getObjectData(primary, 'title1.text');
	const color = getObjectData(primary, 'color');

	const items = data.allPrismicBlogpage.edges.map(x => {
		return {
			link: x.node.url,
			image: getImage(x.node.data.image.gatsbyImageData),
			title: x.node.data.title.text,
			subtitle: x.node.first_publication_date,
			content: x.node.data.content.text,
			pill: x.node.tags[0]
		};
	});

	const tags = items.map(x => x.pill);
	const unqiueTags = [...new Set(tags)];

	return (
        <Items
			color={color}
            overline={overline}
            title={title}
            items={items}
			tags={unqiueTags}
			{...rest}
        />
    );
};

export const query = graphql`
	fragment FragBlogitems on PrismicPageDataBodyBlogitems {
		primary {
			color
            overline {
              text
            }
            title1 {
              text
            }
        }
	}
`;