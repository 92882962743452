import React from 'react';

import { Button } from '#components/button';
import { ContentImage } from '#slices/content-image';
import { getImage } from 'gatsby-plugin-image';
import { getNestedDocumentData, getObjectData, shortenContent } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';

export const ContentFeatured = (props) => {
	const { className, children, color, image, overline, position, ...rest } = props;
    
    return (
        <ContentImage className={className} color={color} image={image} overline={overline} position={position} {...rest}>
           {children}
        </ContentImage>
    );
};

export const ContentFeaturedSlice = (props) => {
	const { primary } = props.slice;

    const color = getObjectData(primary, 'color');
    const position = getObjectData(primary, 'position');
    const overrideOverline = getObjectData(primary, 'override_overline.text');
    const overrideImage = getImage(getObjectData(primary, 'override_image.url'));
    const overrideButtonLabel = getObjectData(primary, 'override_button_label.text');
    const overrideButtonLink = getObjectData(primary, 'override_button_link.url');
    const overrideText = getObjectData(primary, 'override_text.text');
    const overrideTitle = getObjectData(primary, 'override_title.text');
    const linkDocumentData = getObjectData(primary, 'link_item.document');
    const nestedData = getNestedDocumentData(linkDocumentData);

    const overline = overrideOverline ? overrideOverline : 'Featured';
    const image = overrideImage ? overrideImage : getImage(nestedData.image);
    const buttonLabel = overrideButtonLabel ? overrideButtonLabel : 'Read more';
    const buttonLink = overrideButtonLink ? overrideButtonLink : nestedData.url;
    
    const text = overrideText ? overrideText : shortenContent(nestedData.content) || shortenContent(nestedData.subtitle);
    const title = overrideTitle ? overrideTitle : nestedData.title;

    return (
        <ContentFeatured color={color} image={image} overline={overline} position={position}>
            <ContentImage.ContentImageItem
                first={true}
                single={true}
                shorten={true}
                overline={overline}
                title={title}
                button={<Button to={buttonLink}>{buttonLabel}</Button>}
            >
                {text}
            </ContentImage.ContentImageItem>
        </ContentFeatured>
    );
};

export const query = graphql`
	fragment FragContentfeatured on PrismicPageDataBodyContentfeatured {
        id
        primary {
            color
            position
            override_image {
                gatsbyImageData(
					placeholder: BLURRED
				)
            }
            override_overline {
                text
            }
            override_button_label {
                text
            }
            override_button_link {
                url
            }
            override_text {
                text
            }
            override_title {
                text
            }
            link_item {
                document {
                    ... on PrismicBlogpage {
                        tags
                        url
                        data {
                            title {
                                text
                            }
                            content {
                                text
                            }
                            image {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    width: 900
					                height: 600
                                )
                            }
                        }
                    }
                    ... on PrismicPage {
                        tags
                        url
                        data {
                            body {
                                ... on PrismicPageDataBodySplash {
                                    id
                                    primary {
                                        subtitle {
                                            text
                                        }
                                        image {
                                            gatsbyImageData(
                                                placeholder: BLURRED
                                                width: 900
					                            height: 600
                                            )
                                        }
                                        title1 {
                                            text
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
	}
`;