// extracted by mini-css-extract-plugin
export var cmtBase = "styles-module--cmt-base--0b139";
export var cmtBaseB1 = "styles-module--cmt-base-b1--a6886";
export var cmtBaseB2 = "styles-module--cmt-base-b2--81b44";
export var cmtBaseB3 = "styles-module--cmt-base-b3--33b12";
export var cmtBaseB4 = "styles-module--cmt-base-b4--6782f";
export var cmtBaseB5 = "styles-module--cmt-base-b5--f2ddf";
export var cmtBaseH1 = "styles-module--cmt-base-h1--93513";
export var cmtBaseH2 = "styles-module--cmt-base-h2--78a1c";
export var cmtBaseH3 = "styles-module--cmt-base-h3--d367c";
export var cmtBaseH4 = "styles-module--cmt-base-h4--fa394";
export var cmtBaseH5 = "styles-module--cmt-base-h5--8e201";
export var cmtBaseH6 = "styles-module--cmt-base-h6--440f7";
export var cmtBaseH7 = "styles-module--cmt-base-h7--2c971";
export var cmtBaseImage = "styles-module--cmt-base-image--9e995";
export var cmtBaseList = "styles-module--cmt-base-list--b9c14";
export var cmtBaseO1 = "styles-module--cmt-base-o1--0df82";
export var cmtBaseO2 = "styles-module--cmt-base-o2--3c156";
export var cmtBaseParagraph = "styles-module--cmt-base-paragraph--bc80e";
export var cmtList__ordered = "styles-module--cmt-list__ordered--5797d";
export var cmtList__unordered = "styles-module--cmt-list__unordered--0d359";