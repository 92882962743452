import React, { useContext, useEffect } from 'react';

import { FancyText } from '#components/fancy-text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GlobalContext } from '#providers/global';
import { Parallax } from 'react-scroll-parallax';
import { Pill } from '#components/pill';
import { SliceColumn, SliceContainer } from '#slices/slice';
import { Typography } from '#components/typography';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import NightRiderIcon from '#icons/nightrider';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Splash = (props) => {
	const { slice, pill, className } = props;
	const title = getObjectData(slice, 'primary.title1.text');
	const subtitle = getObjectData(slice, 'primary.subtitle.text');
	const image = getImage(getObjectData(slice, 'primary.image.gatsbyImageData'));
	const { setShowBackdrop, showBackdrop } = useContext(GlobalContext);
	
	const containerClasses = classNames(
		styles.cmtSSplash,
		className
	);

	useEffect(() => {
		setShowBackdrop(false);
	}, []);

	return (
		<div className={containerClasses}>
			<Parallax className={styles.cmtSSplash__imageWrapper} speed={-10}>
				<div className={styles.cmtSSplash__imageContainer} >
					<GatsbyImage
						loading='eager'
						className={styles.cmtSSplash__image}
						image={image}
						alt='Splash Image'
					/>
				</div>
			</Parallax>
			<SliceContainer className={styles.cmtSSplash__sliceContainer}>
				<SliceColumn className={styles.cmtSSplash__sliceColumn} col>
					<div className={styles.cmtSSplash__container}>
						<div className={styles.cmtSSplash__content}> 
							{
								pill && (
									<div className={styles.cmtSSplash__pill}>
										<Pill variant={Pill.VARIANT.TERTIARY}>{pill}</Pill>
									</div>
								)
							}
							<span className={styles.cmtSSplash__title}>
							{
								!showBackdrop && <FancyText>{title}</FancyText>
							}
							</span>
							<Typography className={styles.cmtSSplash__subtitle} variant={Typography.VARIANT.B2}>{subtitle}</Typography>
						</div>
						<div className={styles.cmtSSplash__nightrider}>
							<NightRiderIcon className={styles.cmtSSplash__nightriderIcon} />
						</div>
					</div>
				</SliceColumn>
			</SliceContainer>
		</div>
	);
};

export const query = graphql`
	fragment FragSplash on PrismicPageDataBodySplash {
		primary {
            image {
				gatsbyImageData(
					placeholder: DOMINANT_COLOR
					width: 2560 
					height: 1440 
				)
            }
            title1 {
              	text
            }
			subtitle {
				text
			}
          }
	}
`;