export const VARIANT = {
	H1: 0,
	H2: 1,
	H3: 2,
	H4: 3,
	H5: 4,
	H6: 5,
	H7: 6,
	B1: 7,
	B2: 8,
	B3: 9,
	B4: 10,
	O1: 11,
	O2: 12,
	O3: 13
};

export const WEIGHT = {
	NORMAL: 0,
	LIGHT: 1
};