export const VARIANT = {
	PRIMARY: 0,
	SECONDARY: 1,
	TERTIARY: 2
};

export const SIZE = {
	SMALL: 0,
	MEDIUM: 1
};
