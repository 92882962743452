import React, { useCallback, useEffect, useState } from 'react';

import { Linker } from '#components/linker';
import { SIZE } from './constants';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const DrawerButton = (props) => {
	const { className, children, to, open, size, ...rest } = props;
	const [active, setActive] = useState(false);

	useEffect(() => {
		const active = open !== undefined;
		setActive(active);
	}, [open]);

	const containerClasses = classNames(
		styles.cmtDrawerAccordianButton,
		{
			[styles.cmtDrawerAccordianButtonLarge]: size === SIZE.LARGE
		},
		className
	);

	const onClick = useCallback(() => setActive(!active), [active]);

	return (
		<li className={containerClasses} {...rest}>
			<Linker to={to} className={styles.cmtDrawerAccordianButton__link}>
				<button
					className={styles.cmtDrawerAccordianButton__heading}
					tabIndex={0}
					type='button'
					onClick={onClick}
					aria-expanded={active.toString}
				>
					{children}
				</button>
			</Linker>
		</li>
	);
};

DrawerButton.SIZE = SIZE;
export default DrawerButton;