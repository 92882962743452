// extracted by mini-css-extract-plugin
export var cmtPaper = "styles-module--cmt-paper--9d501";
export var cmtPaperColorDark = "styles-module--cmt-paper--color-dark--d3997";
export var cmtPaperColorPrimary = "styles-module--cmt-paper--color-primary--88604";
export var cmtPaperColorSecondary = "styles-module--cmt-paper--color-secondary--f33cc";
export var cmtPaperColorTransparent = "styles-module--cmt-paper--color-transparent--7943d";
export var cmtPaperPaddingLarge = "styles-module--cmt-paper--padding-large--74608";
export var cmtPaperPaddingMedium = "styles-module--cmt-paper--padding-medium--e6065";
export var cmtPaperPaddingNone = "styles-module--cmt-paper--padding-none--c67cd";
export var cmtPaperPaddingSmall = "styles-module--cmt-paper--padding-small--25775";
export var cmtPaperRadiusLarge = "styles-module--cmt-paper--radius-large--987f9";
export var cmtPaperRadiusMedium = "styles-module--cmt-paper--radius-medium--cd459";
export var cmtPaperRadiusNone = "styles-module--cmt-paper--radius-none--2001b";
export var cmtPaperRadiusSmall = "styles-module--cmt-paper--radius-small--fd7f4";
export var cmtPaperShadowLarge = "styles-module--cmt-paper--shadow-large--046ca";
export var cmtPaperShadowMedium = "styles-module--cmt-paper--shadow-medium--ebc77";
export var cmtPaperShadowNone = "styles-module--cmt-paper--shadow-none--ad3e2";
export var cmtPaperShadowSmall = "styles-module--cmt-paper--shadow-small--401ab";