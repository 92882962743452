import React from 'react';

import { Content } from '#slices/content';
import { Footer } from '#components/footer';
import { MODE } from '#providers/mode';
import { Nav } from '#components/nav';
import { NavMain } from '#components/nav-main';
import { ParallaxProvider } from 'react-scroll-parallax';
import { SEO } from '#components/seo';
import { SimpleSplash } from '#slices/simple-splash';
import { UpNext } from '#slices/up-next';
import { getFirst, getObjectData } from '#utils/prismicParseHelpers';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { linkResolver } from '#utils/linkResolver';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Wrapper from '../wrapper';

const Template = ({ data }) => {
	const { currentBlog, allBlogs } = data;
	const uid = getObjectData(currentBlog, 'uid');
	const tag = getFirst(getObjectData(currentBlog, 'tags'));
	const date = getObjectData(currentBlog, 'first_publication_date');
	const content = getObjectData(currentBlog, 'data.content.richText');
	const image = getImage(getObjectData(currentBlog, 'data.image.gatsbyImageData'));
	const title = getObjectData(currentBlog, 'data.title.text');

	const currentIndex = allBlogs.nodes.findIndex(x => x.uid === uid);
	const nextArticle = allBlogs.nodes[currentIndex + 1];

	let nextData = null;
	if (nextArticle) {
		nextData = {
			title: getObjectData(nextArticle, 'data.title.text'),
			image: getObjectData(nextArticle, 'data.image.gatsbyImageData'),
			category: getFirst(getObjectData(nextArticle, 'tags')),
			url: getObjectData(nextArticle, 'url')
		};
	} else {
		const firstArticle = getFirst(getObjectData(allBlogs, 'nodes'));
		nextData = {
			title: getObjectData(firstArticle, 'data.title.text'),
			image: getImage(getObjectData(firstArticle, 'data.image.gatsbyImageData')),
			category: getFirst(getObjectData(firstArticle, 'tags')),
			url: getObjectData(firstArticle, 'url')
		};
	}

	return (
		<ParallaxProvider>
			<Wrapper>
				<NavMain />
				<Nav mode={MODE.DARK} />
				<SimpleSplash
					category={tag}
					title={title}
					image={image}
					date={date}
				/>
				<Content>{content}</Content>
				{
					nextData && (
						<UpNext
							variant={UpNext.VARIANT.ARTICLE}
							title={nextData.title}
							category={nextData.category}
							link={nextData.url}
							image={nextData.image}
							animate={false} 
						/>
					)
				}
				<Footer />
			</Wrapper>
		</ParallaxProvider>
	);
};

export const Head = (props) => {
	const { data } = props;

	const location = props.location.pathname;
	const title = data.currentBlog.data.title.text;
	const image = data.currentBlog.data.seo_title.text;
	const seoDescription = data.currentBlog.data.seo_description.text;
	const seoTitle = data.currentBlog.data.seo_title.text;

	return (
		<SEO
			title={title}
			seoTitle={seoTitle}
			seoDesciption={seoDescription}
			image={image}
			url={location}
			type={SEO.TYPE.ARTICLE}
		/>
	);
};

export const pageQuery = graphql`
	query LayoutBlog($uid: String!) {
		currentBlog: prismicBlogpage(uid: { eq: $uid }) {
			_previewable
			uid
			url
			data {
				seo_description {
					text
				}
				seo_title {
					text
				}
				content {
					richText
				}
				image {
					gatsbyImageData(
						placeholder: BLURRED
					)
				}
				title {
					text
				}
			}
			tags
			first_publication_date(formatString: "DD MMMM yyyy")
		}
		allBlogs: allPrismicBlogpage(
			sort: {fields: [first_publication_date], order: DESC}
		) {
			nodes {
				uid
				_previewable
				url
				tags
				first_publication_date(formatString: "DD MMMM yyyy")
				data {
					image {
						gatsbyImageData(
							placeholder: BLURRED
						)
					}
					title {
						text
					}
				}
			}
		}
	}
`;

export default withPrismicPreview(Template,
	[
		{
			repositoryName: 'cometa-website',
			linkResolver
		}
	]
);