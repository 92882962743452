import React, { createContext, useContext, useRef } from 'react';

export const IdContext = createContext({
	id: () => {
		throw new Error('Unimplemented');
	}
});

export const useIdGetter = (prefix, reset = false) => {
	const ref = useRef();
	if (!ref.current || reset) {
		const me = { id: 0, get: () => `${prefix}-${me.id++}` };
		ref.current = me;
	}
	return ref.current?.get;
};

export const useId = (override) => {
	const getter = useContext(IdContext).id;
	const ref = useRef();
	if (!ref.current) {
		ref.current = getter();
	}

	if (override) {
		return override;
	}
	return ref.current;
};

export const useGetId = () => {
	const getter = useContext(IdContext).id;
	const base = useRef();
	if (!base.current) {
		base.current = getter();
	}
	return useIdGetter(base.current, true);
};

export const useIndex = () => {
	const ref = useRef();
	if (!ref.current) {
		const me = { id: 0, get: () => '' + me.id++ };
		ref.current = me;
	}
	
	ref.current.id = 0;
	return ref.current.get;
};

export const IdProvider = (props) => {
	const { idPrefix = 'id', children } = props;
	const id = useIdGetter(idPrefix);
	return <IdContext.Provider value={{ id }}>{children}</IdContext.Provider>;
};
