import { POSITION, VARIANT } from './constants';
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const IconButton = (props) => {
	const {
		className,
		onClick,
		children,
		disabled,
		selected,
		position,
		variant,
		icon,
		...rest
	} = props;

	const [hover, setHover] = useState(selected);

	useEffect(() => {
		setHover(selected);
	}, [selected]);

	const containerClasses = classNames(
		styles.cmtIconButton,
		{
			[styles.cmtIconButtonSelected]: hover,
			[styles.cmtIconButtonVariantClear]: variant === VARIANT.CLEAR,
			[styles.cmtIconButtonPositionRight]: position === POSITION.RIGHT
		},
		className
	);

	const wrappedOnClick = useCallback(
		ev => {
			if (!(disabled)) {
				ev.preventDefault();
				onClick && onClick(ev);
			}
		},
		[onClick]
	);

	return (
		<button
			aria-label='Button'
			className={containerClasses}
			tabIndex={0}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			disabled={disabled}
			onClick={wrappedOnClick}
			{...rest}>
			<div className={styles.cmtIconButton__icon}>{icon}</div>
			{children && <span className={styles.cmtIconButton__text}>{children}</span>}
		</button>
	);
};

IconButton.defaultProps = {
	position: POSITION.LEFT,
	variant: VARIANT.SOLID
};

IconButton.POSITION = POSITION;
IconButton.VARIANT = VARIANT;
export default IconButton;
