const urlify = (string) => string.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();

const linkResolver = doc => {

	if (doc.type === 'blogpage') {
		if (Array.isArray(doc.tags) && doc.tags.length) {
			const tag = urlify(doc.tags[0]);
			return `/news/${tag}/${doc.uid}`;
		}
	}

	if (doc.type === 'policypage') {
		return `/policies/${doc.uid}`;
	}

	if (doc.type === 'page') {
		const docUID = doc.uid.split('_')[0];

		if (docUID === 'home') {
			return '/';
		}

		if (Array.isArray(doc.tags) && doc.tags.length) {
			const tag = urlify(doc.tags[0]);
			return `/${tag}/${docUID}`;
		}

		return `/${docUID}`;
	}

	return '/';
};

module.exports.linkResolver = linkResolver;