import React, { useMemo, useState } from 'react';

import { CardStack } from '#slices/card-stack';
import { IconButton } from '#components/icon-button';
import { ItemsCard } from './components/items-card';
import { Observer } from '#components/observer';
import { Tab, TabGroup } from '#components/tabs';
import LoadIcon from '#icons/load';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

const ANY_TAB = 'All';
const INITIAL_AMOUNT = 6;

export const Items = (props) => {
	const { title, overline, tags, items, color, ...rest } = props;
    const [intersecting, setIntersecting] = useState(false);
    const [selectedTab, setSelectedTab] = useState(ANY_TAB);
    const [showAmount, setShowAmount] = useState(1);

    const onChange = isIntersecting => {
        if (isIntersecting) {
            setIntersecting(isIntersecting);

            setTimeout(() => {
                setShowAmount(showAmount + 1);
                setIntersecting(false);
            }, 1000);
        }
	};

    const loadingClasses = classNames(
        styles.cmtSNews__loadMoreIcon,
        {
            [styles.cmtSNews__loadMoreIconLoading]: intersecting
        }
    );

    const handleTabChange = (selected) => {
        setShowAmount(1);
        setSelectedTab(selected);
    };

    // return total items  && filtered items
    const filteredData = useMemo(() => {
        if (selectedTab === ANY_TAB) {
            return {
                totalItems: items.length,
                items: [...items].slice(0, INITIAL_AMOUNT*showAmount)
            };
        }

        const filterItems = items.filter(x => x.pill === selectedTab);

        return {
            totalItems: filterItems.length,
            items: filterItems.slice(0, INITIAL_AMOUNT*showAmount)
        };

    }, [selectedTab, items, showAmount]);

	return (
        <div>
            <CardStack
                color={color}
                title={title}
                overline={overline}
                slot={
                    (filteredData.totalItems !== filteredData.items.length) && (
                        <Observer rootMargin='0% 0%' onChange={onChange}>
                            <div className={styles.cmtSNews__loadMoreWrapper}>
                                <div className={styles.cmtSNews__loadMoreContainer}>
                                    <div className={styles.cmtSNews__loadMore}>
                                        <IconButton className={loadingClasses} icon={<LoadIcon />} />
                                        <span className={styles.cmtSNews__loadMoreText}>Load More</span>
                                    </div>
                                </div>
                            </div>
                        </Observer> 
                    )
                }
                tab={
                    tags && (
                        <TabGroup onChange={(val) => handleTabChange(val)} defaultSelected={selectedTab}>
                            <Tab name={ANY_TAB} label={ANY_TAB} />
                            { tags.map((x, i) => <Tab key={i} name={x} label={x} />) }
                        </TabGroup>
                    )
                }
                {...rest}
            >
            {
                filteredData.items.map((x, i) => {
                    const cardPadding = x.cardPadding;
                    const link = x.link;
                    const image = x.image;
                    const title = x.title;
                    const subtitle = x.subtitle;
                    const pill = x.pill;
                    const text = x.content ? x.content.substring(0, 200) : null;

                    return (
                        <div key={i} className={styles.cmtSNews__card} style={{ animationDelay: `${0.05 * (i+1)}s` }}>
                            <ItemsCard key={`${i}`} padding={cardPadding} link={link} image={image} title={title} subtitle={subtitle} pill={pill} text={text} />
                        </div>
                    );
                })
            }
            </CardStack>
        </div>
    );
};