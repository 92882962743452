import { COLOR, PADDING, RADIUS, SHADOW } from './constants';
import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Paper = (props) => {
	const { className, color, radius, shadow, children, onHover, padding, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtPaper,
		{
			[styles.cmtPaperColorPrimary]: color === COLOR.PRIMARY,
			[styles.cmtPaperColorSecondary]: color === COLOR.SECONDARY,
			[styles.cmtPaperColorTransparent]: color === COLOR.TRANSPARENT,
			[styles.cmtPaperRadiusNone]: radius === RADIUS.NONE,
			[styles.cmtPaperRadiusSmall]: radius === RADIUS.SMALL,
			[styles.cmtPaperRadiusMedium]: radius === RADIUS.MEDIUM,
			[styles.cmtPaperRadiusLarge]: radius === RADIUS.LARGE,
			[styles.cmtPaperShadowNone]: shadow === SHADOW.NONE,
			[styles.cmtPaperShadowSmall]: shadow === SHADOW.SMALL,
			[styles.cmtPaperShadowMedium]: shadow === SHADOW.MEDIUM,
			[styles.cmtPaperShadowLarge]: shadow === SHADOW.LARGE,
			[styles.cmtPaperPaddingNone]: padding === PADDING.NONE,
			[styles.cmtPaperPaddingSmall]: padding === PADDING.SMALL,
			[styles.cmtPaperPaddingMedium]: padding === PADDING.MEDIUM,
			[styles.cmtPaperPaddingLarge]: padding === PADDING.LARGE
		},
		className
	);

	return (
		<div
			className={containerClasses} 
			onMouseEnter={() => onHover && onHover(true)}
			onMouseLeave={() => onHover && onHover(false)}
			{...rest}
		>
			{children}
		</div>
	);
};

Paper.defaultProps = {
	color: COLOR.PRIMARY,
	radius: RADIUS.NONE,
	shadow: SHADOW.NONE
};

Paper.COLOR = COLOR;
Paper.RADIUS = RADIUS;
Paper.SHADOW = SHADOW;
Paper.PADDING = PADDING;

export default Paper;