import React  from 'react';

import { COLOR, POSITION } from './constants';
import { ContentProvider } from '#providers/content';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LinkCard } from '#components/link-card';
import { Lockup } from '#components/lockup';
import { PrismicRichText } from '@prismicio/react';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const ContentLink = props => {
	const { position, color, image, children, overline, text, title, className } = props;

	const classes = classNames(
		styles.cmtSContentLink, 
		{
			[styles.cmtSContentLinkPositionReverse]: position === POSITION.REVERSE
		},
		className
	);

	return (
		<ContentProvider>
			<Slice className={classes} color={color}>
				<SliceContainer containerClassName={styles.cmtSContentLink__sliceContainerInner}>
						<SliceColumn>
							<div className={styles.cmtSContentLink__imageWrapper}>
								<GatsbyImage
									className={styles.cmtSContentLink__image}
									image={image}
									alt="Splash Image"
								/>
							</div>
						</SliceColumn>
						<SliceColumn>
							<div className={styles.cmtSContentLink__content}>
								<Lockup overline={overline}>{title}</Lockup>
								<div className={styles.cmtSContentLink__text}>
									<PrismicRichText field={text} />
								</div>
								<div className={styles.cmtSContentLink__download}>{children}</div>
							</div>
						</SliceColumn>
				</SliceContainer>
			</Slice>
		</ContentProvider>
	);
};


export const ContentLinkSlice = ({ slice }) => {
	const { primary, items } = slice;

	const color = getObjectData(primary, 'color');
	const position = getObjectData(primary, 'position');
	const image = getImage(getObjectData(primary, 'image.gatsbyImageData'));
	const overline = getObjectData(primary, 'overline.text');
	const title = getObjectData(primary, 'title1.text');
	const text = getObjectData(primary, 'text.richText');

	const cardColor = color === COLOR.PRIMARY ? COLOR.SECONDARY : COLOR.PRIMARY;
	
	return (
		<ContentLink 
			position={position} 
			color={color} 
			image={image} 
			overline={overline} 
			title={title} 
			text={text}
		>
			{
				items.map((x, i) => {
					const label = x.button_label.text;
					const link = x.link.url;
					const title = x.title1.text;

					return (
						<LinkCard key={i} color={cardColor} link={link} label={label} className={styles.cmtSContentLink__downloadItem}>{title}</LinkCard>
					);
				})
			}
		</ContentLink>
	);
};

export const query = graphql`
	fragment FragContentlink on PrismicPageDataBodyContentlink {
		primary {
			color
			position
			image {
				gatsbyImageData(
					placeholder: BLURRED
					width: 900
					height: 600
				)
            }
            overline {
				text
            }
			text {
				richText
            }
            title1 {
				text
            }
        }
        items {
			button_label {
				text
            }
			link {
				url
            }
			title1 {
				text
            }
		}
	}
`;