import { Link as GatsbyLink } from 'gatsby';
import { linkResolver } from '#utils/linkResolver';
import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Linker = (props) => {
	const { to, type, uid, className, contain, inline, children, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtLink,
		{
			[styles.cmtLinkContain]: contain,
			[styles.cmtLinkInline]: inline
		},
		className
	);

	let Component;
	let add;

	if (to) {
		Component = GatsbyLink;
	}	add = { to };
	if (uid) {
		add.to = linkResolver({ uid, type });
	} else if (!/^\/(?!\/)/.test(to)) {
		Component = 'a';
		add.href = to;

		if (/^http/.test(to)) {
			add.target = '_blank';
		}
	}

	if (!Component || !to) {
		return <>{children}</>;
	}

	return (
		<Component className={containerClasses} {...add} {...rest}>
			{children}
		</Component>
	);
};

export default Linker;