import React from 'react';

import { Paper } from '#components/paper';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Slice = ({ className, containerClassName, backgroundImage, children, color, containerProps, ...rest }) => {
	const classes = classNames(
		styles.cmtSSlice,
		className
	);

	const containerClasses = classNames(
		styles.cmtSSlice__container,
		containerClassName
	);

	const backgroundProp = {
		...(backgroundImage && { backgroundImage: `url(${backgroundImage})` })
	};

	return (
		<section className={classes} {...rest}>
			<Paper className={containerClasses} color={color} style={backgroundProp} {...containerProps}>
				{children}
			</Paper>
		</section>
	);
};

Slice.defaultProps = {
	color: Paper.COLOR.WHITE
};

export default Slice;
Slice.COLOR = Paper.COLOR;

export const SliceContainer = React.forwardRef((props, ref) => {
	const { className, containerClassName, reverse, children, ...rest } = props;

	const classes = classNames(
		styles.cmtSSliceContainer,
		{
			[styles.cmtSSliceContainerReverse]: reverse
		},
		className
	);

	const containerClasses = classNames(
		styles.cmtSSliceContainer__container,
		containerClassName
	);


	return (
		<div className={classes} ref={ref} {...rest}>
			<div className={containerClasses}>
				{children}
			</div>
		</div>
	);
});

export const SliceColumn = ({ className, col, noPadding, children, ...rest }) => {
	const containerClasses = classNames(
		styles.cmtSSliceColumn,
		{
			[styles.cmtSSliceColumnNoPadding]: noPadding,
			[styles.cmtSSliceColumnCol]: col
		},
		className
	);

	return (
		<div className={containerClasses} {...rest}>
			{children}
		</div>
	);
};