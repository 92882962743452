import { IconButton } from '#components/icon-button';
import { Linker } from '#components/linker';
import { Paper } from '#components/paper';
import { Typography } from '#components/typography';
import CollapseIcon from '#icons/arrow-right';
import React, { useState } from 'react';

import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const LinkCard = (props) => {
	const { className, to, children, label, color, icon, ...rest } = props;
	const [hovered, setHovered] = useState(false);

	const containerClasses = classNames(
		styles.cmtDownloadCard,
		className
	);

	return (
		<Linker to={to} className={styles.cmtDownloadCard__linker}>
			<Paper 
				color={color}
				className={containerClasses}
				radius={Paper.RADIUS.SMALL}
				shadow={hovered ? Paper.SHADOW.MEDIUM : Paper.SHADOW.NONE}
				onHover={(v) => setHovered(v)}
				{...rest}
			>
				<div className={styles.cmtDownloadCard__title}>
					<Typography variant={Typography.VARIANT.B2}>{children}</Typography>
				</div>
				<div className={styles.cmtDownloadCard__button}>
					{
						icon ? (icon) : (
							<IconButton selected={hovered} position={IconButton.POSITION.LEFT} icon={<CollapseIcon />}>{label}</IconButton>
						)
					}
				</div>
			</Paper>
		</Linker>
	);
};

export default LinkCard;