import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const CardActions = (props) => {
	const { className, children, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtCardActions,
		className
	);

	return (
		<div className={containerClasses} {...rest}>
			<div className={styles.cmtCardActions__holder}>
				{children}
			</div>
		</div>
	);
};

export default CardActions;
