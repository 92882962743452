export const getFirst = (input) => {
    if (Array.isArray(input)) {
        return input[0];
    }

    return undefined;
};

export const getObjectData = (obj, path) => {
    const keys = path.split('.');
    let result = obj;
    for (const key of keys) {
      if (!result || !Object.prototype.hasOwnProperty.call(result, key)) {
        return undefined;
      }
      result = result[key];
    }
    return result;
};

export const getNestedDocumentData = (obj) => {
    if (obj) {
        const tag = getFirst(getObjectData(obj, 'tags'));
        const url = getObjectData(obj, 'url');

        const sliceObj = getObjectData(obj, 'data.body');
        if (sliceObj) {
            const item = sliceObj.filter(obj => Object.keys(obj).length !== 0)[0];
            return {
                category: tag,
                url: url,
                title: getObjectData(item, 'primary.title1.text') || getObjectData(item, 'primary.title.text'),
                subtitle: getObjectData(item, 'primary.subtitle.text'),
                image: getObjectData(item, 'primary.image.gatsbyImageData')
            };
        } else {
            return {
                category: tag,
                url: url,
                image: getObjectData(obj, 'data.image.gatsbyImageData'),
                title: getObjectData(obj, 'data.title.text'),
                content: getObjectData(obj, 'data.content.text')
            };
        }
    } 
    
    return undefined;
};


export const shortenContent = (input, maxLength = 500) => {
    if (input) {
        return input.length > maxLength ? input.substring(0, maxLength - 3) + '...' : input;
    }
};
