import React, { useContext, useEffect, useState } from 'react';

import { GlobalContext } from '#providers/global';
import { Linker } from '#components/linker';
import { Observer } from '#components/observer';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import { useSize } from '#hooks/use-size';
import ArrowLeft from '#icons/arrow-left';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const GoBack = (props) => {
	const { className, children, isStatic, link, onClick, ...rest } = props;
    const { showFab, setShowFab, setFabLink } = useContext(GlobalContext);
    const [hovered, setHovered] = useState(false);
    
    const size = useSize();
    const rootMargin = size === 'DESKTOP' ? '0% 0% -50%' : '0% 0% 0%';

	const classes = classNames(
		styles.cmtSGoBack,
        {
            [styles.cmtSGoBackHovered]: hovered
        },
		className
	);

    useEffect(() => {
        setShowFab(true);
        setFabLink(link);

        const handleRouteUpdate = () => {
          setShowFab(false);
        };

        // eslint-disable-next-line camelcase
        window.___gatsby_fic_server__ = () => {
          handleRouteUpdate();
        };

        return () => {
          delete window.___gatsby_fic_server__;
        };
      }, []);

    const onObserverChange = (val) => {
        if (val) {
            setShowFab(false);
        }
    };

	return (
        <Slice 
            className={classes}
            containerClassName={styles.cmtSGoBack__sliceInner}
            onClick={onClick}
            {...rest}
        >
            <Linker to={link} className={styles.cmtSGoBack__linker}>
                <SliceContainer
                    className={styles.cmtSGoBack__sliceContainer}
                    containerClassName={styles.cmtSGoBack__sliceContainerInner}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <SliceColumn className={styles.cmtSGoBack__sliceColumn}>
                        <div className={styles.cmtSGoBack__imageWrapper}>
                        {
                            (!isStatic && showFab) ? (
                                <div className={styles.cmtSGoBack__intersection}>
                                    <div className={styles.cmtSGoBack__intersectionContainer}>
                                        <Observer rootMargin={rootMargin} onChange={(val) => onObserverChange(val)}>
                                            <div className={styles.cmtSGoBack__intersectionPoint} />
                                        </Observer>
                                    </div>
                                </div>
                            ) : (
                                <ArrowLeft className={styles.cmtSGoBack__icon} />
                            )
                        }
                        </div>
                        <span className={styles.cmtSGoBack__text}>{children}</span>
                    </SliceColumn>
                </SliceContainer>
            </Linker>
        </Slice>
	);
};

export const GoBackSlice = (props) => {
	const { primary } = props.slice;
    const title = getObjectData(primary, 'title1.text');
    const link = getObjectData(primary, 'link.url');

	return <GoBack link={link}>{title}</GoBack>;
};

export const query = graphql`
	fragment FragGoback on PrismicPageDataBodyGoback {
        primary {
            title1 {
              text
            }
            link {
                url
            }
        }
	}
`;