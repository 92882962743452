import { Linker } from '#components/linker';
import { Typography } from '#components/typography';

import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const NavMegaItem = (props) => {
	const { to, title, children } = props;

	const containerClasses = classNames(
		styles.cmtNavMegaItem
	);

	return (
		<Linker to={`${to}`} className={styles.cmtNavMegaItem__link}>
			<div className={containerClasses}>
				<Typography variant={Typography.VARIANT.B3} className={styles.cmtNavMegaItem__title} >{title}</Typography>
				{ 
					children && (
						<Typography variant={Typography.VARIANT.B4} className={styles.cmtNavMegaItem__description}>{children}</Typography>
					)
				}
			</div>
		</Linker>
	);
};

export default NavMegaItem;