import React from 'react';

import { COLOR } from './constants';
import { Card, CardContent, CardHeader, CardMedia, CardText, CardWrapper } from '#components/card';
import { Lockup } from '#components/lockup';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { getFirst, getObjectData } from '#utils/prismicParseHelpers';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Swiper from 'react-id-swiper';
import classNames from 'classnames';

import 'swiper/scss';
import * as styles from './styles.module.scss';

const SWIPER_PARAMS = {
	slidesPerView: 'auto',
	spaceBetween: 16,
	freeMode: true,
	mousewheel: true,
	direction: 'horizontal',
	mousewheelEventsTarged: '.swiper-container',
	navigation: false,
	pagination: true,
	scrollbar: {
		el: '.swiper-scrollbar',
		hide: false
	}
};

export const CardHorizontal = props => {
	const { children, color, title, overline, className } = props;

	const classes = classNames(
		styles.cmtSCardHorizontal, 
		className
	);

	return (
		<Slice className={classes} color={color}>
			<SliceContainer>
				<SliceColumn col>
                    <Lockup className={styles.cmtSCardHorizontal__lockup} overline={overline}>{title}</Lockup>
					<div className={styles.cmtSCardHorizontal__container}>
						<Swiper {...SWIPER_PARAMS}>
							{children}
						</Swiper>
					</div>
					<div className="swiper-scrollbar"></div>
				</SliceColumn>
			</SliceContainer>
		</Slice>
	);
};


export const CardHorizontalSlice = ({ slice }) => {
	const { primary, items } = slice;
	const color = getObjectData(primary, 'color');
	const overline = getObjectData(primary, 'overline.text');
	const title = getObjectData(primary, 'title1.text');
	const cardColor = color === COLOR.PRIMARY ? COLOR.SECONDARY : COLOR.PRIMARY;

	return (
		<CardHorizontal color={color} title={title} overline={overline}>
		{
			items.map((x, i) => {
				const document = getObjectData(x, 'link.document.data.body'); 
				const image = getImage(getObjectData(x, 'image.gatsbyImageData')) || getImage(getObjectData(getFirst(document), 'primary.image.gatsbyImageData'));
				const subtitle = getObjectData(x, 'subtitle.text');
				const text = getObjectData(x, 'text.text');
				const title = getObjectData(x, 'title1.text');
				const pill = getObjectData(x, 'pill.text');

				return (
                    <CardWrapper key={i}>
                        <Card color={cardColor} style={{ height: 514 }}>
                            <CardMedia label={pill} image={image} />
                            <CardContent>
                                <CardHeader subtitle={subtitle}>{title}</CardHeader>
                                <CardText>{text}</CardText>
                            </CardContent>
                        </Card>
                    </CardWrapper>
				);
			})
		}
		</CardHorizontal>
	);
};

export const query = graphql`
	fragment FragCardhorizontal on PrismicPageDataBodyCardhorizontal {
		slice_type 
        primary {
			color
			overline {
				text
			}
			title1 {
				text
			}
		}
		items {
			image {
				gatsbyImageData(
					placeholder: BLURRED
				)
			}
			link {
				document {
                    ... on PrismicPage {
                      data {
                        body {
                          ... on PrismicPageDataBodySplash {
                            primary {
								image {
									gatsbyImageData(
										placeholder: BLURRED
									)
                              	}
                            }
                          }
                        }
                      }
                    }
                  }
			}
			pill {
				text
			}
			subtitle {
				text
			}
			button_label {
				text
			}
			text {
				text
			}
			title1 {
				text
			}
		}
	}
`;