import React, { useContext, useEffect } from 'react';

import { GlobalContext } from '#providers/global';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Backdrop = props => {
	const { className, open, ...rest } = props;
	const { showBackdrop } = useContext(GlobalContext);
	const theBackdropOpen = showBackdrop ? showBackdrop : open;

	const containerClasses = classNames(
		styles.cmtBackdrop,
		{
			[styles.cmtBackdropOpen]: theBackdropOpen
		},
		className
	);

	useEffect(() => {
		if (typeof document !== 'undefined') {
			document.body.style.overflow = theBackdropOpen ? 'hidden' : '';
		}
	}, [theBackdropOpen]);

	return (
		<div className={containerClasses}>
			<div className={styles.cmtBackdrop__backdrop} {...rest} />
			<div className={styles.cmtBackdrop__bottom} />
		</div>
	);
};

export default Backdrop;
