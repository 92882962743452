let timeoutId;

export const throttle = (fn, delay) => {
    return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
    
        timeoutId = setTimeout(() => {
          fn(...args);
          timeoutId = null;
        }, delay);
      };
};