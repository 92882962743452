import { SIZE, VARIANT } from './constants';

import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Pill = (props) => {
	const {
		className,
		variant,
		size,
		children,
		...rest
	} = props;

	const containerClasses = classNames(
		styles.cmtPill,
		{
			[styles.cmtPillVariantPrimary]: variant === VARIANT.PRIMARY,
			[styles.cmtPillVariantSecondary]: variant === VARIANT.SECONDARY,
			[styles.cmtPillVariantTertiary]: variant === VARIANT.TERTIARY,
			[styles.cmtPillSmall]: size === SIZE.SMALL
		},
		className
	);

	return (
		<div className={containerClasses} {...rest}>
			<div className={styles.cmtPill__inner}>
				{children}
			</div>
		</div>
	);
};

Pill.defaultProps = {
	variant: VARIANT.PRIMARY,
	size: SIZE.MEDIUM
};

export default Pill;
Pill.SIZE = SIZE;
Pill.VARIANT = VARIANT;