import { VARIANT, WEIGHT } from './constants';
import React from 'react';
import classNames from 'classnames';
const { H1, H2, H3, H4, H5, H6, H7, B1, B2, B3, B4, O1 } = VARIANT;
const { LIGHT } = WEIGHT;
import * as styles from './styles.module.scss';

export const Typography = (props) => {
	let {
		className,
		component,
		variant,
		weight,
		children,
		...rest
	} = props;

	const typeClassName = classNames(
		styles.cmtTypography,
		{
			[styles.cmtTypographyLight]: weight === LIGHT,
			[styles.cmtTypographyH1]: variant === H1,
			[styles.cmtTypographyH1]: variant === H1,
			[styles.cmtTypographyH2]: variant === H2,
			[styles.cmtTypographyH3]: variant === H3,
			[styles.cmtTypographyH4]: variant === H4,
			[styles.cmtTypographyH5]: variant === H5,
			[styles.cmtTypographyH6]: variant === H6,
			[styles.cmtTypographyH7]: variant === H7,
			[styles.cmtTypographyB1]: variant === B1,
			[styles.cmtTypographyB2]: variant === B2,
			[styles.cmtTypographyB3]: variant === B3,
			[styles.cmtTypographyB4]: variant === B4,
			[styles.cmtTypographyO1]: variant === O1
		},
		className
	);

	const newProps = { className: typeClassName, ...rest };
	component || (component = 'span');

	const element = React.createElement(component, { ...newProps }, children);
	return element;
};

Typography.defaultProps = {
	weight: WEIGHT.NORMAL
};

Typography.VARIANT = VARIANT;
Typography.WEIGHT = WEIGHT;

export default Typography;
