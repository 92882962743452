import React from 'react';

import { ContentProvider } from '#providers/content';
import { Lockup } from '#components/lockup';
import { PrismicRichText } from '@prismicio/react';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';

import * as styles from './styles.module.scss';

export const Content = props => {
	const { title, overline, children, color, type } = props;

	const content = <PrismicRichText field={children} />;

	return (
		<ContentProvider type={type}>
			<Slice color={color}>
				<SliceContainer containerClassName={styles.cmtContent__wrapper}>
					<SliceColumn col className={styles.cmtContent__column}>
						<div className={styles.cmtContent__container}>
							{ title && <Lockup overline={overline}>{title}</Lockup> }
							<div className={styles.cmtContent__content}>{content}</div>
						</div>
					</SliceColumn>
				</SliceContainer>
			</Slice>
		</ContentProvider>
	);
};

Content.TYPE = ContentProvider.TYPE;

export const ContentSlice = ({ slice }) => {
	const { primary } = slice;
	const title = getObjectData(primary, 'title1.text');
	const overline = getObjectData(primary, 'overline.text');
	const content = getObjectData(primary, 'text.richText');
	const color = getObjectData(primary, 'color');

	return (
		<Content title={title} overline={overline} color={color}>
			{content}
		</Content>
	);
};

export const query = graphql`
	fragment FragContent on PrismicPageDataBodyContent {
		primary {
			color
			button {
				url
			}
			overline {
				text
			}
			text {
				richText
			}
			title1 {
				text
			}
		}
	}
`;