import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Linker } from '#components/linker';

import Lottie from 'lottie-react';
import reavealAnimation from '#lottie/reveal-black.json';

import * as styles from './styles.module.scss';

import { Hamburger } from '#components/hamburger';

export const Drawer = (props) => {
	const { open, className, children, showHamburger, onHamburger, ...rest } = props;
	const [isOpen, setIsOpen] = useState(open);

	const containerClasses = classNames(
		styles.cmtDrawer,
        {
			[styles.cmtDrawerOpen]: open
        },
		className
	);

    useEffect(() => {
		setIsOpen(open);
	}, [open]);

	useEffect(() => {
		if (typeof document !== 'undefined') {
			document.body.style.overflow = isOpen ? 'hidden' : '';
		}
	}, [isOpen]);

	const Logo = () => {
		return (
			<div>
				<Linker to='/'>
					<Lottie className={styles.cmtDrawer__lottie} animationData={reavealAnimation} loop={false} />
				</Linker>
			</div>
		);
	};

	return (
		<nav className={containerClasses} aria-hidden={(!isOpen).toString()} {...rest}>
			<header className={styles.cmtDrawer__header}>
				<div className={styles.cmtDrawer__headerLeft}>
					<Logo />
				</div>
				{
					showHamburger && (
						<div className={styles.cmtDrawer__hamburgerWrapper}>
							<div className={styles.cmtDrawer__hamburger}>
								<Hamburger open={isOpen} onClick={onHamburger} />
							</div>
						</div>
					)
				}
			</header>
			<div className={styles.cmtDrawer__itemsContainer}>
				<div className={styles.cmtDrawer__items} role='presentation'>
					<ul className={styles.cmtDrawer__accordian}>
						{children}
					</ul>
				</div>
			</div>
		</nav>
	);
};

Drawer.defaultProps = {
	open: false
};

export default Drawer;
