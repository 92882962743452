import React, { useEffect, useState } from 'react';

import Lottie from 'lottie-react';
import classNames from 'classnames';
import revealAnimation from '#lottie/reveal-white.json';

import * as styles from './styles.module.scss';

export const Loader = ({ onLoadComplete }) => {
    const [loaderStarted, setLoaderStarted] = useState(false);
    const [loaderFinished, setLoaderFinished] = useState(false);

    const [lottieStarted, setLottieStarted] = useState(false);

    useEffect(() => {
      setLoaderStarted(true);
      if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden';
      }
    }, []);

    useEffect(() => {
      const timer = setTimeout(() => {
        setLottieStarted(true);
      }, 1200);
    
      return () => clearTimeout(timer);
    }, []);
  
    const classes = classNames(
      styles.cmtLoader,
      {
        [styles.cmtLoaderStart]: loaderStarted,
        [styles.cmtLoaderEnd]: loaderFinished
      }
    );

    const onComplete = () => {
      setTimeout(() => {
        setLoaderFinished(true);
        onLoadComplete();
        
        if (typeof document !== 'undefined') {
          document.body.style.overflow = '';
        }
        return;
      }, 20);
    };
   
    return (
      <div className={classes}>
          <div className={styles.cmtLoader__inner}>
            { 
              lottieStarted && (
                <Lottie 
                  className={styles.cmtLoader__lottie} 
                  animationData={revealAnimation} 
                  loop={false} 
                  onComplete={() => onComplete()} 
                  cache='true'
                  renderer='svg' 
                />
              )
            }
          </div>
      </div>
  );
};