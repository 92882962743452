import { Linker } from '#components/linker';
import { PADDING } from '../constants';
import { Paper } from '#components/paper';
import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Card = (props) => {
	const { className, color, padding, to, children, onClick, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtCard,
		{
			[styles.cmtCardHover]: to,
			[styles.cmtCardPaddingLarge]: padding === PADDING.LARGE
		},
		className
	);

	return (
		<Linker className={styles.cmtCard__linker} to={to} target='_blank'>
			<Paper
				className={containerClasses}
				radius={Paper.RADIUS.MEDIUM}
				color={color}
				onClick={onClick}
				{...rest}>
				{children}
			</Paper>
		</Linker>
	);
};

Card.defaultProps = {
	color: Paper.COLOR.PRIMARY,
	padding: PADDING.NONE
};

Card.COLOR = Paper.COLOR;
Card.PADDING = PADDING;
export default Card;