import { COLOR } from './constants';
import React, { useState } from 'react';

import { Button } from '#components/button';
import { CardStackCard } from './components/card-stack-card';
import { Lockup } from '#components/lockup';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { getFirst, getObjectData } from '#utils/prismicParseHelpers';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

const INITIAL_AMOUNT = 6;

export const CardStack = ({ className, tab, color, title, overline, slot, children, ...rest }) => {
	const containerClassName = classNames(
		styles.cmtSCardStack,
		className
	);
	
	return (
		<Slice className={containerClassName} color={color} {...rest}>
			<SliceContainer>
				<SliceColumn col>
					<Lockup 
						className={styles.cmtSCardStack__lockup}
						overline={overline}
						size={Lockup.SIZE.SMALL}
					>
						{title}
					</Lockup>
					<div className={styles.cmtSCardStack__contentWrapper}>
						<div className={styles.cmtSCardStack__content}>
							<div className={styles.cmtSCardStack__tabs}>{tab}</div>
							<div className={styles.cmtSCardStack__cards}>{children}</div>
							<div className={styles.cmtSCardStack__slot}>{slot}</div>
						</div>
					</div>
				</SliceColumn>
			</SliceContainer>
		</Slice>
	);
};

export default CardStack;

export const CardStackSlice = ({ slice }) => {
	const [showCount, setShowCount] = useState(1);

	const { primary, items } = slice;
	const color = getObjectData(primary, 'color1');
	const title = getObjectData(primary, 'title1.text');
	const overline = getObjectData(primary, 'overline.text');

	const remainingCount = items.length - (INITIAL_AMOUNT * showCount);
	const hasRemaining = remainingCount > 0;
	const cardItems = items.slice(0, INITIAL_AMOUNT * showCount);

	const button = hasRemaining ? <Button onClick={() => setShowCount(showCount + 1)}>Show More</Button> : <></>;

	return (
		<CardStack color={color} title={title} overline={overline} slot={button}>
		{
			cardItems.map((x, i) => {
				const document = getFirst(getObjectData(x, 'link.document.data.body'));
				const image = getImage(getObjectData(x, 'image.gatsbyImageData')) || getImage(getObjectData(document, 'primary.image.gatsbyImageData'));
				const link = getObjectData(x, 'link.url');
				const subtitle = getObjectData(x, 'subtitle.text');
				const text = getObjectData(x, 'text.text');
				const title = getObjectData(x, 'title1.text');
				const pill = getObjectData(x, 'pill.text');
				const label = getObjectData(x, 'button_label.text');

				const cardColor = color === COLOR.PRIMARY ? COLOR.SECONDARY : COLOR.PRIMARY;

				return (
					<CardStackCard
						key={i}
						className={styles.cmtSCardStack__card}
						color={cardColor}
						pill={pill}
						image={image}
						title={title}
						subtitle={subtitle}
						text={text}
						label={label}
						link={link}
					/>
				);
			})
		}
		</CardStack>
	);
};

export const query = graphql`
	fragment FragCardstack on PrismicPageDataBodyCardstack {
		primary {
			color1
			overline {
				text
			}
			title1 {
				text
			}
		}
		items {
			image {
				gatsbyImageData(
					placeholder: BLURRED
				)
			}
			link {
				url
				document {
                    ... on PrismicPage {
                      data {
                        body {
                          ... on PrismicPageDataBodySplash {
                            primary {
								image {
									gatsbyImageData(
										placeholder: BLURRED
									)
                              	}
                            }
                          }
                        }
                      }
                    }
                  }
			}
			pill {
				text
			}
			subtitle {
				text
			}
			button_label {
				text
			}
			text {
				text
			}
			title1 {
				text
			}
		}
	}
`;