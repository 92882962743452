import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Pointer = forwardRef((props, ref) => {
	const { className, show, ...rest } = props;
	const divRef = useRef(null);

    useImperativeHandle(ref, () => divRef.current);

	const containerClasses = classNames(
		styles.cmtPointer,
		{
			[styles.cmtPointerShow]: show
		},
		className
	);

	return (
		<div ref={divRef} className={containerClasses} {...rest}>
			<span className={styles.cmtPointer__text}>View</span>
		</div>
	);
});

export default Pointer;