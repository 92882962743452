import React, { useCallback, useRef, useState } from 'react';

import { Button } from '#components/button';
import { Lockup } from '#components/lockup';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { Typography } from '#components/typography';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import CheckIcon from '#assets/icons/check';
import Lottie from 'lottie-react';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';
import loaderAnimation from '#lottie/loader-black.json';

import * as styles from './styles.module.scss';

const FORM_NAME = 'supplier-form';
const GATSBY_SITE_RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;

const SUBMISSION_STATUS = {
	RESTING: 0,
	READY: 1,
	SUBMITTING: 2,
	FAILED: 3,
	SUBMITTED: 4
};

export const FormUpload = (props) => {

	const { restingOverline, restingText, restingTitle, submittedOverline, submittedText, submittedTitle } = props;
	const { typeLabel, accept = 'application/pdf' } = props;
	const fileRef = useRef(null);

	const [isDragging, setIsDragging] = useState(false);
	const [status, setStatus] = useState(SUBMISSION_STATUS.RESTING);
	const [captchaKey, setCaptchaKey] = useState('');
	const [errorMsg, setErrorMsg] = useState(null);
	const acceptingDrop = status === SUBMISSION_STATUS.RESTING;

	const handleSubmit = async(event) => {
		event.preventDefault();
		setStatus(SUBMISSION_STATUS.SUBMITTING);

		try {
			const formData = new FormData(event.target);
			formData.append('type', 'New Submission');
			formData.append('g-recaptcha-response', captchaKey);
			formData.append('date', Date.now());

			const response = await fetch('/', {
				method: 'POST',
				body: formData
			});

			if (response.status === 200) {
				setStatus(SUBMISSION_STATUS.SUBMITTED);
			} else {
				setErrorMsg('Form submission failed. Please try again later or contact us for help.');
				setStatus(SUBMISSION_STATUS.ERROR);
			}
		} catch (error) {
			setErrorMsg('Form submission failed. Please try again later or contact us for help.');
			setStatus(SUBMISSION_STATUS.ERROR);
		}

		return false;
	};

	const setDragging = useCallback(() => {
		if (acceptingDrop) {
			setIsDragging(true);
		}
	}, [acceptingDrop]);

	const setNotDragging = useCallback(() => {
		setIsDragging(false);
	}, []);

	const onDragOver = useCallback(
		ev => {
			if (acceptingDrop) {
				ev.preventDefault();
			}
		},
		[acceptingDrop, isDragging]
	);

	const validateFile = (file) => {
		setErrorMsg(null);

		if (!file) {
			fileRef.current.value = '';
			setErrorMsg('No file found. Please upload a file.');
			return;
		} else if (file.type !== 'application/pdf') {
			fileRef.current.value = '';
			setErrorMsg('Invalid file type. Only PDF files are allowed.');
			return;
		} else if (file.size > (1024 * 1024) * 8) {
			fileRef.current.value = '';
			setErrorMsg('File size is too large. Maximum file size allowed is 8MB.');
			return;
		};

		fileRef.current.value = '';
		const fileList = new DataTransfer();
		fileList.items.add(file);
		fileRef.current.files = fileList.files;

		setErrorMsg(null);
		setStatus(SUBMISSION_STATUS.READY);
	};

	const onDrop = useCallback(event => {
		event.preventDefault();

		if (acceptingDrop) {
			setIsDragging(false);
			event.preventDefault();

			const file = event.dataTransfer.files[0];
			validateFile(file);
		}
	}, [acceptingDrop]);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		validateFile(file);
	};

	const hasFile = fileRef.current && (fileRef.current.files.length > 0 ? true : false);

	const boxClasses = classNames(
		styles.cmtSFormUpload,
		{
			[styles.cmtSFormUploadDragging]: isDragging,
			[styles.cmtSFormUploadReady]: hasFile,
			[styles.cmtSFormUploadError]: errorMsg
		}
	);

	const overline = status === SUBMISSION_STATUS.SUBMITTED ? submittedOverline : restingOverline;
	const title = status === SUBMISSION_STATUS.SUBMITTED ? submittedTitle : restingTitle;
	const text = status === SUBMISSION_STATUS.SUBMITTED ? submittedText : restingText;
	
	const canSubmit = captchaKey && hasFile;

	return (
		<Slice color={Slice.COLOR.SECONDARY} style={{ position: 'relative' }}>
			{
				status === SUBMISSION_STATUS.SUBMITTING && (
					<div className={styles.cmtSFormUpload__loader}>
						<Lottie className={styles.cmtSFormUpload__lottie} animationData={loaderAnimation} loop={true} />
					</div>
				)
			}
			<SliceContainer>
				<SliceColumn col>
					<Lockup overline={overline}>{title}</Lockup>
					<div className={styles.cmtSFormUpload__outer}>
						<Typography className={styles.cmtSFormUpload__title}>{text}</Typography>
						{
							status !== SUBMISSION_STATUS.SUBMITTED && (
								<form
									className={styles.cmtSFormUpload__form}
									name={FORM_NAME}
									method="POST"
									data-netlify="true"
									data-netlify-recaptcha="true"
									onSubmit={handleSubmit}
									// eslint-disable-next-line react/no-unknown-property
									netlify-honeypot="bot-field"
								>
									<input type="hidden" name="form-name" value={FORM_NAME} />
									<div
										className={boxClasses}
										onDragEnter={setDragging}
										onDragOver={onDragOver}
										onDragLeave={setNotDragging}
										onDrop={onDrop}>
										<input
											ref={fileRef}
											id='supplier-input'
											name='file'
											type='file'
											className={styles.cmtSFormUpload__hiddenInput}
											accept={accept}
											onChange={handleFileChange}
										/>
										{
											(status === SUBMISSION_STATUS.RESTING || status === SUBMISSION_STATUS.ERROR)  && (
												<div className={styles.cmtSFormUpload__resting}>
													<label className={styles.cmtSFormUpload__restingLabel} htmlFor='supplier-input'>
														Drag and drop file or upload a file
													</label>
													<span className={styles.cmtSFormUpload__restingText}>{typeLabel}</span>
												</div>
											)
										}
										{
											status === SUBMISSION_STATUS.READY && (
												<div className={styles.cmtSFormUpload__ready}>
													<div className={styles.cmtSFormUpload__readyIcon}>
														<CheckIcon />
													</div>
													<div className={styles.cmtSFormUpload__readyTextWrapper}>
														<span className={styles.cmtSFormUpload__readyText}>File ready to be uploaded</span>
													</div>
												</div>
											)
										}
									</div>
									<div className={styles.cmtSFormUpload__message}>
										<span className={styles.cmtSFormUpload__messageDot} />
										<Typography variant={Typography.VARIANT.B4}>Maximum file size: 8 MB</Typography>
									</div>
									{
										errorMsg && (
											<div className={styles.cmtSFormUpload__message}>
												<span className={styles.cmtSFormUpload__messageDotError} />
												<Typography variant={Typography.VARIANT.B4}>{errorMsg}</Typography>
											</div>
										)
									}
									{/* <div data-netlify-recaptcha="true"></div> */}
									<div className={styles.cmtSFormUpload__bottomBox}>
										<ReCAPTCHA onChange={setCaptchaKey} sitekey={GATSBY_SITE_RECAPTCHA_KEY} />
										<Button disabled={!canSubmit} className={styles.cmtSFormUpload__button} type="submit">Submit</Button>
									</div>
								</form>
							)
						}
					</div>
				</SliceColumn>
			</SliceContainer>
		</Slice>
	);
};

export const FormUploadSlice = ({ slice }) => {
	const { primary } = slice;
	const restingOverline = getObjectData(primary, 'resting_overline.text');
	const restingTitle = getObjectData(primary, 'resting_title.text');
	const restingText = getObjectData(primary, 'resting_text.text');
	const submittedOverline = getObjectData(primary, 'submitted_overline.text');
	const submittedTitle = getObjectData(primary, 'submitted_title.text');
	const submittedText = getObjectData(primary, 'submitted_text.text');

	return (
		<FormUpload 
			restingOverline={restingOverline}
			restingTitle={restingTitle}
			restingText={restingText} 
			submittedOverline={submittedOverline}
			submittedTitle={submittedTitle}
			submittedText={submittedText}
		/>
	);
};

export const query = graphql`
	fragment FragFormupload on PrismicPageDataBodyFormupload {
		id
		primary {
			resting_overline {
				text
			}
			resting_text {
				text
			}
			resting_title {
				text
			}
			submitted_overline {
				text
			}
			submitted_text {
				text
			}
			submitted_title {
				text
			}
		}
	}
`;
