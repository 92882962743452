// extracted by mini-css-extract-plugin
export var cmtBase = "styles-module--cmt-base--61452";
export var cmtBaseB1 = "styles-module--cmt-base-b1--8faa7";
export var cmtBaseB2 = "styles-module--cmt-base-b2--70673";
export var cmtBaseB3 = "styles-module--cmt-base-b3--cb301";
export var cmtBaseB4 = "styles-module--cmt-base-b4--d91c2";
export var cmtBaseB5 = "styles-module--cmt-base-b5--2fb57";
export var cmtBaseH1 = "styles-module--cmt-base-h1--9f1a6";
export var cmtBaseH2 = "styles-module--cmt-base-h2--aff7d";
export var cmtBaseH3 = "styles-module--cmt-base-h3--f825f";
export var cmtBaseH4 = "styles-module--cmt-base-h4--16e51";
export var cmtBaseH5 = "styles-module--cmt-base-h5--9a5a3";
export var cmtBaseH6 = "styles-module--cmt-base-h6--3e32d";
export var cmtBaseH7 = "styles-module--cmt-base-h7--25b68";
export var cmtBaseImage = "styles-module--cmt-base-image--85f8c";
export var cmtBaseList = "styles-module--cmt-base-list--51a38";
export var cmtBaseO1 = "styles-module--cmt-base-o1--0e8ff";
export var cmtBaseO2 = "styles-module--cmt-base-o2--3ed92";
export var cmtBaseParagraph = "styles-module--cmt-base-paragraph--69279";
export var cmtTypography = "styles-module--cmt-typography--2b56e";
export var cmtTypographyB1 = "styles-module--cmt-typography--b1--8e165";
export var cmtTypographyB2 = "styles-module--cmt-typography--b2--e4896";
export var cmtTypographyB3 = "styles-module--cmt-typography--b3--1aa43";
export var cmtTypographyB4 = "styles-module--cmt-typography--b4--a5440";
export var cmtTypographyB5 = "styles-module--cmt-typography--b5--42f77";
export var cmtTypographyH1 = "styles-module--cmt-typography--h1--85ecd";
export var cmtTypographyH2 = "styles-module--cmt-typography--h2--7a0fb";
export var cmtTypographyH3 = "styles-module--cmt-typography--h3--a6097";
export var cmtTypographyH4 = "styles-module--cmt-typography--h4--47a06";
export var cmtTypographyH5 = "styles-module--cmt-typography--h5--15a93";
export var cmtTypographyH6 = "styles-module--cmt-typography--h6--9bed5";
export var cmtTypographyH7 = "styles-module--cmt-typography--h7--f96c4";
export var cmtTypographyLight = "styles-module--cmt-typography--light--85c3d";
export var cmtTypographyO1 = "styles-module--cmt-typography--o1--4a8cf";
export var cmtTypographyO2 = "styles-module--cmt-typography--o2--c4d42";