import { VARIANT } from './constants';
import React, { useMemo } from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const LinearLoader = (props) => {
	const { className, value, variant, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtLinearLoader__wrapper,
		className
	);

	const rootProps = useMemo(() => {
		const addObj = {};
		if (variant === VARIANT.DETERMINATE) {
			addObj['aria-valuenow'] = Math.round(value);
			addObj['aria-valuemin'] = 0;
			addObj['aria-valuemax'] = 100;
		}
		return addObj;
	}, [value, variant]);

	const transformValue = useMemo(() => {
		if (variant === VARIANT.DETERMINATE) {
			return value - 100;
		}
		return 0;
	}, [value, variant]);

	return (
		<div className={containerClasses} {...rest}>
			<div className={styles.cmtLinearLoader} role='progressbar' aria-label='Loading progress' {...rootProps}>
				<div className={styles.cmtLinearLoader__barOne} style={{ transform: `translateX(${transformValue}%)` }} />
				{
                    variant !== VARIANT.DETERMINATE && (
                        <div className={styles.cmtLinearLoader__barTwo} />
                    )
                }
			</div>
		</div>
	);
};

LinearLoader.defaultProps = {
	value: 0,
	variant: VARIANT.INDETERMINATE
};

export default LinearLoader;
LinearLoader.VARIANT = VARIANT;
