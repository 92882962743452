import React, { createContext, useContext } from 'react';

export const MODE = {
	WHITE: 'white',
	LIGHT: 'light',
	DARK: 'dark'
};

const ProviderContext = createContext();

export const useMode = (override) => {
	const modeDefault = useContext(ProviderContext);
	return override || modeDefault;
};

const BaseProvider = ProviderContext.Provider;

export const ModeProvider = props => {
	const { children, mode } = props;
	let theMode = mode;

	return <BaseProvider value={theMode}>{children}</BaseProvider>;
};