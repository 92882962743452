import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const FabPageWrapper = (props) => {
	const { className, children, ...rest } = props;

	const classes = classNames(
		styles.cmtFabPageWrapper,
		className
	);

	return (
        <div className={classes} {...rest}>
            <div className={styles.cmtFabPageWrapper__container}>
                {children}
            </div>
        </div>
	);
};

export default FabPageWrapper;