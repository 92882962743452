import React from 'react';

import { Linker } from '#components/linker';
import { PrismicProvider } from '@prismicio/react';
import { textComponents } from '#utils/prismicRichText';

export const ContentProvider = ({ children, type }) => (
	<PrismicProvider
		richTextComponents={textComponents(type)}
		internalLinkComponent={({ href, ...props }) => (<Linker to={href} {...props} />)}
	>
		{children}
	</PrismicProvider>
);

ContentProvider.TYPE = textComponents.TYPE;
export default ContentProvider;