import { Link } from 'gatsby';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { PrismicProvider } from '@prismicio/react';
import React from 'react';
import UniversalProvider from './src/providers';

import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews';

import { linkResolver } from '#utils/linkResolver';
import NewsTemplate from '#layout/news-page';
import PageTemplate from '#layout/page';
import PolicyTemplate from '#layout/policy-page';

import '#sass/fonts.scss';

export const wrapRootElement = ({ element }) => (
	<UniversalProvider>
		<PrismicProvider
			internalLinkComponent={({ href, ...props }) => (
				<Link to={href} {...props} />
			)}
		>
			<PrismicPreviewProvider repositoryConfigs={
				[{
					repositoryName: process.env.PRISMIC_REPO_NAME,
					linkResolver,
					componentResolver: componentResolverFromMap({
						page: PageTemplate,
						newsPage: NewsTemplate,
						policyPage: PolicyTemplate
					})
				}]
			}>
				{element}
			</PrismicPreviewProvider>
		</PrismicProvider>
	</UniversalProvider>
);
