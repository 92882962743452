import * as styles from './styles.module.scss';
import { graphql } from 'gatsby';
import React, { useState } from 'react';

import { DataItem } from './components/data-item';
import { DataItemContainer } from './components/data-item-container';
import { Lockup } from '#components/lockup';
import { Observer } from '#components/observer';
import { Slice } from '#slices/slice';

import ImagePlaceholder from './image-placeholder.jpg';

import classNames from 'classnames';

// condition for when has less items must just be static etc... 
// must be able to be on blank color too then text black...

const chunk = (arr, size) => 
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
);

export const Data = props => {
	const { children, title, overline } = props;
	const [lock, setLock] = useState(false);

	// useLockBodyScroll({ lock: lock });

	const containerClasses = classNames(
		styles.cmtSData
	);

	const observerClasses = classNames(
		styles.cmtSData__observer,
		{
			[styles.cmtSData__observerIntersecting]: lock
		}
	);

	const handleScroll = (e) => {
		// const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		const reachedBottom = event.target.offsetHeight + event.target.scrollTop >= e.target.clientHeight;

		if (reachedBottom) {
			setLock(false);
		}
	};

	return (
		<Observer rootMargin='0% 0% -100% 0%' className={observerClasses}>
			<Slice className={containerClasses} containerClassName={styles.cmtSData__container} backgroundImage={ImagePlaceholder} containerProps={{ onScroll: handleScroll }}>
				<div className={styles.cmtSData__wrapper} onScroll={handleScroll}>
					<Lockup overline={overline} color={Lockup.COLOR.WHITE}>{title}</Lockup>
					<div className={styles.cmtSData__content}>
						<div className={styles.cmtSData__contentInner}>{children}</div>
					</div>
				</div>
			</Slice>
		</Observer>
	);
};

const createDataContentItem = (items) => {
	const dataItemClasses = (isSecondary = false) => {
		return classNames(
			styles.cmtSData__dataItem,
			{
				[styles.cmtSData__dataItemSecondary]: isSecondary
			}
		);
	};

	return (
		<DataItemContainer>
		{ 
			items.map((x, i) => {
				const title = x.title1.text;
				const text = x.text.text;
				const pill = x.pill.text;
				const isSecondary = i === 1 ? true : false;
				return (
					<DataItem key={i} className={dataItemClasses(isSecondary)} title={title} pill={pill}>
						{text}
					</DataItem>
				);
			})
		}
		</DataItemContainer>
	);
};

export const DataSlice = ({ slice }) => {
	const title = slice.primary.title1.text;
	const overline = slice.primary.overline.text;
	const color = slice.primary.color;
	const items = slice.items;
	const itemsChunked = chunk(items, 2);

	return (
		<Data title={title} overline={overline} color={color}>
			{ itemsChunked.map(x => createDataContentItem(x)) }
		</Data>
	);
};

export const query = graphql`
	fragment FragData on PrismicPageDataBodyData {
		primary {
			color
			overline {
				text
			}
			title1 {
				text
			}
		}
		items {
			pill {
				text
			}
			text {
				text
			}
			title1 {
				text
			}
		}
	}
`;