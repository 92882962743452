import { Paper } from '#components/paper';

export const COLOR = {
    PRIMARY: Paper.COLOR.PRIMARY,
    SECONDARY: Paper.COLOR.SECONDARY
};

export const POSITION = {
    DEFAULT: 'default',
    REVERSE: 'reverse'
};