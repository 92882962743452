import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const UnorderedList = props => {
	const { className, children, ...rest } = props;

	const classes = classNames(
		styles.cmtList__unordered,
		className
	);

	return (
		<ul className={classes} {...rest}>{children}</ul>
	);
};

export const OrderedList = props => {
	const { className, children, ...rest } = props;

	const classes = classNames(
		styles.cmtList__ordered,
		className
	);

	return (
		<ol className={classes} {...rest}>{children}</ol>
	);
};

export const ListItem = props => {
	const { className, children, ...rest } = props;

	const classes = classNames(className);

	return (
		<li className={classes} {...rest}>
			<span>{children}</span>
		</li>
	);
};
