import { Divider } from '#components/divider';
import { Drawer } from '#components/drawer';
import React from 'react';

import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const NavDrawer = (props) => {
    const { children, open, scrolled, onHamburger, mainItems, className, ...rest } = props;

	const classes = classNames(
		styles.cmtNavDrawer,
		{
			[styles.cmtNavDrawerScrolled]: scrolled
		},
		className
	);

	return (
        <Drawer className={classes} open={open} onHamburger={onHamburger} showHamburger={scrolled} {...rest}>
			<div className={styles.cmtNavDrawer__container}>
				{ children }
			</div>
			<Divider className={styles.cmtNavDrawer__divider} /> 
			{ mainItems }
		</Drawer>
	);
};

export default NavDrawer;