import React from 'react';
import classNames from 'classnames';

import { Linker } from '#components/linker';
import { Lockup } from '#components/lockup';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { Typography } from '#components/typography';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import MarkIcon from '#icons/mark';

import * as styles from './styles.module.scss';

const staticQuery = graphql`
    query {
        allPrismicFooter {
            nodes {
                data {
                    main_links {
                        title1 {
                            text
                        }
                        link {
                            url
                        }
                    }
                    links_column_one {
                        link_label {
                            text
                        }
                        link {
                            url
                        }
                    }
                    links_column_two {
                        link_label {
                            text
                        }
                        link {
                            url
                        }
                    }
                    addresses {
                        address {
                            text
                        }
                        country {
                            text
                        }
                        telephone {
                            text
                        }
                    }
                    title {
                        text
                    }
                }
            }
        }
    }
`;

const LinkItem = (props) => {
    const { label, to } = props;
    return (
        <Linker contain to={to} className={styles.cmtFooter__linkWrapper}>
            <Typography className={styles.cmtFooter__link} variant={Typography.VARIANT.B3}>{label}</Typography>
        </Linker>
    );
};

const AddressItem = (props) => {
    const { country, address, telephone, ...rest } = props;

    return (
        <div className={styles.cmtFooter__addressWrapper} {...rest}>
            <div className={styles.cmtFooter__addressContainer}>
                <Typography className={styles.cmtFooter__addressTitle} variant={Typography.VARIANT.H6}>{country}</Typography>
                <div className={styles.cmtFooter__addressDetails}>
                    { address && <Typography className={styles.cmtFooter__addressItem} variant={Typography.VARIANT.B4}>{address}</Typography> }
                    { telephone && <Typography className={styles.cmtFooter__addressItem} variant={Typography.VARIANT.B4}>Tel: {telephone}</Typography> }
                </div>
            </div>
        </div>
    );
};

const MainItem = (props) => {
    const { link, children } = props;
    return (
        <Linker contain className={styles.cmtFooter__socialItem} to={link}>
            <Typography className={styles} variant={Typography.VARIANT.H6}>
                {children}
            </Typography>
        </Linker>
    );
};

export const Footer = (props) => {
	const { className, ...rest } = props;
    const staticData = useStaticQuery(staticQuery);
	const { data } = useMergePrismicPreviewData(staticData);

    const {
        title,
        addresses,
        links_column_one: linksColumnOne,
        links_column_two: linksColumnTwo,
        main_links: mainLinks
    } = data.allPrismicFooter.nodes[0].data;

	const containerClasses = classNames(
		styles.cmtFooter,
		className
	);

	return (
        <Slice>
            <SliceContainer className={styles.cmtFooter__sliceContainer}>
                <SliceColumn col>
                    <footer className={containerClasses} {...rest}>
                        <MarkIcon className={styles.cmtFooter__mark} />
                        <Lockup className={styles.cmtFooter__lockup} size={Lockup.SIZE.SMALL}>{title.text}</Lockup>
                        <div className={styles.cmtFooter__social}>
                            {
                                mainLinks.map((x, i) => {
                                    const link = x.link.url;
                                    const title = x.title1.text;
                                    return (
                                       <MainItem key={i} link={link}>{title}</MainItem>
                                    );
                                })
                            }
                        </div>
                        <div className={styles.cmtFooter__address}>
                        {
                                addresses.map((x, i) => {
                                    const country = x.country.text;
                                    const address = x.address.text;
                                    const telephone = x.telephone.text;
                                    return (
                                        <AddressItem 
                                            key={i}
                                            address={address}
                                            country={country}
                                            telephone={telephone} 
                                        />
                                    );
                                })
                            }
                        </div>
                        <div className={styles.cmtFooter__links}>
                            <div className={styles.cmtFooter__linksContainer}>
                                <div className={styles.cmtFooter__column}>
                                    { 
                                        linksColumnOne.map((x, i) => {
                                            const linkUrl = x.link.url;
                                            const linkLabel = x.link_label.text;
                                            return <LinkItem key={i} label={linkLabel} to={linkUrl} />;
                                        }) 
                                    }
                                </div>
                                <div className={styles.cmtFooter__column}>
                                    { 
                                        linksColumnTwo.map((x, i) => {
                                            const linkUrl = x.link.url;
                                            const linkLabel = x.link_label.text;
                                            return <LinkItem key={i} label={linkLabel} to={linkUrl} />;
                                        }) 
                                    }
                                </div>
                            </div>
                            <div className={styles.cmtFooter__copyright}>
                                <span className={styles.cmtFooter__copyrightText}>© 2023 Cometa Trading Société Anonyme</span>
                            </div>
                        </div>
                    </footer>
                </SliceColumn>
            </SliceContainer>
        </Slice>
	);
};

export default Footer;

export const query = graphql`
	fragment FragFooter on PrismicPageDataBodyFooter {
        id
	}
`;