import React from 'react';

import { Divider } from '#components/divider';
import { Typography } from '#components/typography';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const CardText = (props) => {
	const { className, children, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtCardText,
		className
	);

	return (
		<>
			{ children && <Divider/> }
			<div className={styles.cmtCardText__container}>
				<Typography
					className={containerClasses}
					variant={Typography.VARIANT.B4}
					{...rest}>
					{children}
				</Typography>
			</div>
		</>
	);
};

export default CardText;
