import * as styles from './styles.module.scss';
import React from 'react';

import classNames from 'classnames';

export const DataItemContainer = ({ className, children }) => {
    const containerClasses = classNames(
		styles.cmtSDataItemContainer,
		className
	);

	return (
		<div className={containerClasses}>
            {children}
		</div>
	);
};

export default DataItemContainer;