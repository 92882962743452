import React, { useCallback, useEffect, useState } from 'react';

import { IconButton } from '#components/icon-button';
import { Typography } from '#components/typography';
import CollapseIcon from '#icons/collapse';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const AccordianItem = (props) => {
	const { className, children, open, title, ...rest } = props;
	const [active, setActive] = useState(false);
	const [hovered, setHovered] = useState(false);

	useEffect(() => {
		const active = open !== undefined;
		setActive(active);
	}, [open]);

	const containerClasses = classNames(
		styles.cmtAccordianItem,
		{
			[styles.cmtAccordianItemActive]: active,
			[styles.cmtAccordianItemHovered]: hovered
		},
		className
	);

	const onClick = useCallback(() => setActive(!active), [active]);

	return (
		<li
			className={containerClasses} 
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			{...rest}
		>
			<div
				className={styles.cmtAccordianItem__heading}
				tabIndex={0}
				role='button'
				onClick={onClick}
				aria-expanded={active.toString}
				aria-controls={title}
			>
				<div className={styles.cmtAccordianItem__titleWrapper}>
					<div className={styles.cmtAccordianItem__titleContainer}>
						<Typography className={styles.cmtAccordianItem__title}  variant={Typography.VARIANT.H6}>
							{title}
						</Typography>
					</div>
					<div className={styles.cmtAccordianItem__chevronHolder}>
						<IconButton className={styles.cmtAccordianItem__chevron} icon={<CollapseIcon />} />
					</div>
				</div>
			</div>
			<div id={title} className={styles.cmtAccordianItem__content}>
				<span className={styles.cmtAccordianItem__text}>{children}</span>
			</div>
		</li>
	);
};

export default AccordianItem;
