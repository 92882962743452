import React from 'react';

const Icon = ({ className }) => (
    <svg className={className} fill='#000' width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1855_939)">
            <path d="M15.9998 26.668L17.8798 24.788L10.4398 17.3346L26.6665 17.3346L26.6665 14.668L10.4398 14.668L17.8798 7.21464L15.9998 5.33464L5.33317 16.0013L15.9998 26.668Z"/>
        </g>
        <defs>
            <clipPath id="clip0_1855_939">
                <rect x="32" y="32" width="32" height="32" rx="4" transform="rotate(180 32 32)"/>
            </clipPath>
        </defs>
    </svg>
);

export default Icon;