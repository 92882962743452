import React, { useState } from 'react';

import { Card, CardActions, CardContent, CardHeader, CardMedia, CardText } from '#components/card';
import { IconButton } from '#components/icon-button';
import CollapseIcon from '#icons/arrow-right';

export const ItemsCard = (props) => {
	const { padding, link, image, pill, title, text, subtitle, ...rest } = props;
    const [hovered, setHovered] = useState(false);

	return (
        <Card padding={padding} to={link} onHover={(v) => setHovered(v)} {...rest}>
            {image && <CardMedia label={pill} image={image} /> }
            <CardContent>
                <CardHeader subtitle={subtitle}>{title}</CardHeader>
                { text && <CardText>{text}</CardText> }
                <CardActions>
                    <IconButton selected={hovered} position={IconButton.POSITION.RIGHT} icon={<CollapseIcon />}>Read more</IconButton>
                </CardActions>
            </CardContent>
        </Card>
    );
};

ItemsCard.PADDING = Card.PADDING;
export default ItemsCard;
