import { POSITION } from './constants';
import { graphql } from 'gatsby';
import React from 'react';

import { Button } from '#components/button';
import { ContentImageItem } from './components/content-image-item';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import classNames from 'classnames';

import { getObjectData } from '#utils/prismicParseHelpers';

import * as styles from './styles.module.scss';

export const ContentImage = props => {
	const { position, color, image, children, className } = props;

	const classes = classNames(
		styles.cmtSContentImage,
		{
			[styles.cmtSContentImagePositionReverse]: position === POSITION.REVERSE,
			[styles.cmtSContentImageSingleItem]: React.Children.toArray(children).length === 1
		},
		className
	);

	return (
		<Slice className={classes} color={color}>
			<SliceContainer containerClassName={styles.cmtSContentImage__sliceInnerContainer}>
				<SliceColumn noPadding>
					<div className={styles.cmtSContentImage__imageWrapper}>
						<div className={styles.cmtSContentImage__imageInner}>
							<GatsbyImage
								className={styles.cmtSContentImage__image}
								image={image}
								alt="Splash Image"
							/>
						</div>
					</div>
				</SliceColumn>
				<SliceColumn>
					<div className={styles.cmtSContentImage__content}>
						<div className={styles.cmtSContentImage__items}>
							<div className={styles.cmtSContentImage__line} />
							<div className={styles.cmtSContentImage__contentWrapper}>
							{children}
							</div>
						</div>
					</div>
				</SliceColumn>
			</SliceContainer>
		</Slice>
	);
};

ContentImage.ContentImageItem = ContentImageItem;

export const ContentImageSlice = ({ slice }) => {
	const { primary, items } = slice;

	const color = getObjectData(primary, 'color');
	const image = getImage(getObjectData(primary, 'image.gatsbyImageData'));
	const overline = getObjectData(primary, 'overline.text');
	const position = getObjectData(primary, 'position');

	return (
		<ContentImage color={color} image={image} overline={overline} position={position}>
			{
				items.map((x, i) => {
					const title = getObjectData(x, 'title.text');
					const text = getObjectData(x, 'text.text');
					const buttonTitle = getObjectData(x, 'button_title.text');
					const buttonUrl = getObjectData(x, 'button_link.url');

					return (
						<ContentImageItem
							key={i}
							overline={i === 0 && overline ? overline : null}
							single={items.length === 1}
							first={i === 0}
							last={i === items.length - 1 && i !== 0}
							title={title}
							button={buttonUrl && <Button to={buttonUrl}>{buttonTitle}</Button>}
						>
							{text}
						</ContentImageItem>
					);
				})
			}
		</ContentImage>
	);
};

export const query = graphql`
	fragment FragContentimage on PrismicPageDataBodyContentimage {
		primary {
			color
			image {
				gatsbyImageData(
					placeholder: BLURRED
					width: 900
					height: 600
				)
			}
			overline {
				text
			}
			position
		}
		items {
			title {
				text
			}
			text {
				text
			}
			button_title {
				text
			}
			button_link {
				url
			}
		}
	}
`;