import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import * as styles from './styles.module.scss';

import classNames from 'classnames';

export const NavMegaContainer = forwardRef((props, ref) => {
    const { card, children, active, prevActive, slideFrom, ...rest } = props;
    const divRef = useRef(null);

    useImperativeHandle(ref, () => divRef.current);

	const containerClasses = classNames(
		styles.cmtNavMegaContainer,
        {
            [styles.cmtNavMegaContainerActive]: active,
            [styles.cmtNavMegaContainerPrevActive]: prevActive,
            [styles.cmtNavMegaContainerSlideLeft]: slideFrom === 'left' && active,
            [styles.cmtNavMegaContainerSlideRight]: slideFrom === 'right' && active
        }
	);
    
	return (
        <div ref={divRef} className={containerClasses} {...rest}>
            <div className={styles.cmtNavMegaContainer__container}>
                <div className={styles.cmtNavMegaContainer__card}>{card}</div>
                <div className={styles.cmtNavMegaContainer__line} />
                <div className={styles.cmtNavMegaContainer__itemsWrapper}>
                    <div className={styles.cmtNavMegaContainer__items}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
	);
});

export default NavMegaContainer;