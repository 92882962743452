import React, { useEffect } from 'react';

import classNames from 'classnames';
import gsap from 'gsap';

import * as styles from './styles.module.scss';

export const FancyText = (props) => {
	const { className, children, ...rest } = props;

	const containerClasses = classNames(
		styles.cmtFancyText,
		className
	);

	const lineMaxLen = 12;
	const wsLookup = 12;
	const regex = new RegExp(String.raw`\s*(?:(\S{${lineMaxLen}})|([\s\S]{${lineMaxLen - wsLookup},${lineMaxLen}})(?!\S))`, 'g');
	const text = children.replace(regex, (_, x, y) => x ? `${x}-\n` : `${y}\n`).split(/\r?\n/).filter(e => e);

	useEffect(() => {
		let textAnimation = gsap.timeline({ repeat: 0 });
		textAnimation = textAnimation.from('.text', {
			ease: 'power4.easeOut',
			y: '100%',
			opacity: 1,
			skewY: 0.3,
			stagger: {
				amount: 0.29
			}
		});

		return () => textAnimation.clear();
	}, [children]);

	const Letter = ({ space, letter }) => {
		return (space === true ? <div className="text">&nbsp;</div> : <div className="text">{letter}</div>);
	};

	return (
		<div className={containerClasses} {...rest}>
			{
				text.map((x, i) => {
					const item = x.split('');
					return (
						<div key={i} className={styles.cmtFancyText__word}>
							{ item.map((y,i) => <Letter key={i} space={y === ' '} letter={y} />) }
						</div>
					);
				})
			}
		</div>
	);
};

export default FancyText;