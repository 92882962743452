import React, { useState } from 'react';

import { IconButton } from '#components/icon-button';
import { Linker } from '#components/linker';
import { Pill } from '#components/pill';

import ArrowRightIcon from '#icons/arrow-right';
import classNames from 'classnames';

import { navigate } from 'gatsby';

import * as styles from './styles.module.scss';

export const CarouselButton = (props) => {
	const { category, title, url, className, ...rest } = props;
	const [hovered, setHovered] = useState(false);

	const classes = classNames(
        styles.cmtCarouselButton,
		{
			[styles.cmtCarouselButtonHovered]: hovered
		},
        className
    );

	return (
		<Linker to={url} className={styles.cmtCarouselButton__linker}>
			<div
				className={classes}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				{...rest}
			>
				<div className={styles.cmtCarouselButton__content}>
					<div className={styles.cmtCarouselButton__contentContainer}>
						<Pill className={styles.cmtCarouselButton__pill} size={Pill.SIZE.MEDIUM}>{category}</Pill>
						<div key={title} className={styles.cmtCarouselButton__title}>{title}</div>
					</div>
				</div>
				<div className={styles.cmtCarouselButton__iconButtonWrapper}>
					<IconButton selected={hovered} variant={IconButton.VARIANT.CLEAR} onClick={() => navigate(url)} icon={<ArrowRightIcon className={styles.cmtCarouselButton__icon} />} />
				</div>
			</div>
		</Linker>
	);
};

export default CarouselButton;