// extracted by mini-css-extract-plugin
export var cmtSUpNext = "styles-module--cmt-s-up-next--fc923";
export var cmtSUpNextClicked = "styles-module--cmt-s-up-next--clicked--14993";
export var cmtSUpNextShow = "styles-module--cmt-s-up-next--show--daa66";
export var cmtSUpNextVariantArticle = "styles-module--cmt-s-up-next--variant-article--99a79";
export var cmtSUpNext__content = "styles-module--cmt-s-up-next__content--15d78";
export var cmtSUpNext__image = "styles-module--cmt-s-up-next__image--69770";
export var cmtSUpNext__imageContainer = "styles-module--cmt-s-up-next__image-container--98073";
export var cmtSUpNext__link = "styles-module--cmt-s-up-next__link--92ebf";
export var cmtSUpNext__mouseContainer = "styles-module--cmt-s-up-next__mouse-container--18166";
export var cmtSUpNext__overline = "styles-module--cmt-s-up-next__overline--5606e";
export var cmtSUpNext__overlineWrapper = "styles-module--cmt-s-up-next__overline-wrapper--f43e7";
export var cmtSUpNext__sliceContainer = "styles-module--cmt-s-up-next__slice-container--f2909";
export var cmtSUpNext__sliceInner = "styles-module--cmt-s-up-next__slice-inner--a247e";
export var cmtSUpNext__title = "styles-module--cmt-s-up-next__title--5004d";
export var cmtSUpNext__titleContainer = "styles-module--cmt-s-up-next__title-container--37dab";
export var expand = "styles-module--expand--e4361";
export var slideDownOpacity = "styles-module--slideDownOpacity--8637a";
export var slideUpOpacity = "styles-module--slideUpOpacity--83997";