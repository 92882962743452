// extracted by mini-css-extract-plugin
export var cmtSImage = "styles-module--cmt-s-image--267f7";
export var cmtSImageInView = "styles-module--cmt-s-image--in-view--2ac88";
export var cmtSImage__container = "styles-module--cmt-s-image__container--3be8a";
export var cmtSImage__image = "styles-module--cmt-s-image__image--a3085";
export var cmtSImage__imageWrapper = "styles-module--cmt-s-image__image-wrapper--d7d39";
export var cmtSImage__pill = "styles-module--cmt-s-image__pill--01207";
export var cmtSImage__sliceColumn = "styles-module--cmt-s-image__slice-column--89c2b";
export var cmtSImage__sliceContainerInner = "styles-module--cmt-s-image__slice-container-inner--6c9bf";
export var cmtSImage__sliceInner = "styles-module--cmt-s-image__slice-inner--0f6f3";
export var cmtSImage__title = "styles-module--cmt-s-image__title--8c2ab";
export var cmtSImage__wrapper = "styles-module--cmt-s-image__wrapper--c451d";