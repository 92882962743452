import React, { useState } from 'react';

import { Observer } from '#components/observer';
import { Parallax } from 'react-scroll-parallax';
import { Pill } from '#components/pill';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { Typography } from '#components/typography';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './styles.module.scss';

export const Image = (props) => {
	const { title, image, className } = props;
	const [inView, setInView] = useState(false);

	const classes = classNames(
		styles.cmtSImage,
		{
			[styles.cmtSImageInView]: inView
		},
		className
	);

	const onObserverChange = (val) => {
		setInView(val);
	};

	return (
		<Observer rootMargin='0% 0% -70%' onChange={(val) => onObserverChange(val)} triggerOnce>
			<Slice className={classes} containerClassName={styles.cmtSImage__sliceInner}>
				<div className={styles.cmtSImage__wrapper}>
					<Parallax speed={10} className={styles.cmtSImage__imageWrapper}>
						<GatsbyImage
							className={styles.cmtSImage__image}
							image={image}
							alt="Full Image"
						/>
					</Parallax>
					<SliceContainer containerClassName={styles.cmtSImage__sliceContainerInner}>
						<SliceColumn col className={styles.cmtSImage__sliceColumn}>
							<div className={styles.cmtSImage__container}>
								{/* { 
									pill && (
										<div className={styles.cmtSImage__pill}>
											<Pill>{pill}</Pill>
										</div> 
									)
								} */}
								<Typography className={styles.cmtSImage__title} variant={Typography.VARIANT.H3}>{title}</Typography>
							</div>
						</SliceColumn>
					</SliceContainer>
				</div>
			</Slice>
		</Observer>
	);
};

export const ImageSlice = ({ slice }) => {
	const { primary } = slice;
	const image = getImage(getObjectData(primary, 'image.gatsbyImageData'));
	// const pill = getObjectData(primary, 'pill.text');
	const title = getObjectData(primary, 'title1.text');

	return ( 
		<Image title={title} image={image} />
	);
};

export const query = graphql`
	fragment FragImage on PrismicPageDataBodyImage {
		id
		primary {
			pill {
				text
			}
			title1 {
				text
			}
			image {
				gatsbyImageData(
					placeholder: BLURRED
				)
			}
		}
	}
`;