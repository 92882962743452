import { useEffect, useLayoutEffect, useState } from 'react';

function getSize() {
  return typeof window !== 'undefined' && window.innerWidth < 801 ? 'MOBILE' : 'DESKTOP';
}

export const useSize = () => {
  const [size, setSize] = useState('MOBILE');

  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

  useIsomorphicLayoutEffect(() => {
    const handleResize = () => {
      const newSize = getSize();
      if (newSize !== size) {
        setSize(newSize);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size]);

  return size;
};

export default useSize;
