export const COLOR = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TRANSPARENT: 'transparent'
};

export const RADIUS = {
    NONE: 0,
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3
};

export const SHADOW = {
    NONE: 0,
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3
};

export const PADDING = {
    NONE: 0,
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3
};