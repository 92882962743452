import React from 'react';
import classNames from 'classnames';

import { Pill } from '#components/pill';
import { Typography } from '#components/typography';

import * as styles from './styles.module.scss';

export const DataItem = ({ className, title, children, pill, ...rest }) => {
    const containerClasses = classNames(
		styles.cmtSDataItem,
		className
	);

	return (
		<div className={containerClasses} {...rest}>
			<div className={styles.cmtSDataItem__container}>
				<Typography className={styles.cmtSDataItem__title} variant={Typography.VARIANT.H1} color={Typography.COLOR.WHITE}>
					{title}
				</Typography>
				<Pill className={styles.cmtSDataItem__pill} size={Pill.SIZE.SMALL}>
					{pill}
				</Pill>
				<Typography className={styles.cmtSDataItem__text} color={Typography.COLOR.WHITE} variant={Typography.VARIANT.B3}>
					{children}
				</Typography>
			</div>
		</div>
	);
};