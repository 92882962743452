// extracted by mini-css-extract-plugin
export var cmtSGoBack = "styles-module--cmt-s-go-back--3bb8d";
export var cmtSGoBackHovered = "styles-module--cmt-s-go-back--hovered--8acd9";
export var cmtSGoBack__icon = "styles-module--cmt-s-go-back__icon--133a8";
export var cmtSGoBack__imageWrapper = "styles-module--cmt-s-go-back__image-wrapper--276e1";
export var cmtSGoBack__intersection = "styles-module--cmt-s-go-back__intersection--7768b";
export var cmtSGoBack__intersectionContainer = "styles-module--cmt-s-go-back__intersection-container--baacc";
export var cmtSGoBack__intersectionPoint = "styles-module--cmt-s-go-back__intersection-point--75bea";
export var cmtSGoBack__linker = "styles-module--cmt-s-go-back__linker--31aef";
export var cmtSGoBack__sliceColumn = "styles-module--cmt-s-go-back__slice-column--13916";
export var cmtSGoBack__sliceContainer = "styles-module--cmt-s-go-back__slice-container--57a44";
export var cmtSGoBack__sliceContainerInner = "styles-module--cmt-s-go-back__slice-container-inner--04ecb";
export var cmtSGoBack__sliceInner = "styles-module--cmt-s-go-back__slice-inner--6a2de";
export var cmtSGoBack__text = "styles-module--cmt-s-go-back__text--7dda7";