import React, { createContext, useState } from 'react';

export const GlobalContext = createContext({
  showFab: true,
  fabLink: null,
  showBackdrop: false
});

const GlobalProvider = ({ children }) => {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showFab, setShowFab] = useState(true);
  const [fabLink, setFabLink] = useState(null);

  return (
    <GlobalContext.Provider value={{ showBackdrop, setShowBackdrop, showFab, setShowFab, fabLink, setFabLink }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
