import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DIRECTION, useScrollDirection } from '#hooks/use-scroll-detection';
import { DrawerAccordian, DrawerButton } from '#components/drawer';
import { MODE, ModeProvider } from '#providers/mode';
import { NavDrawer } from '../nav-drawer';
import { NavItem } from '../nav-item';
import { NavMega } from '../nav-mega';
import { NavMegaCard } from '../nav-mega-card';
import { NavMegaContainer } from '../nav-mega-container';
import { NavMegaItem } from '../nav-mega-item';
import { NavMenu } from '../nav-menu';
import { SliceContainer } from '#slices/slice';
import { getFirst, getNestedDocumentData, getObjectData } from '#utils/prismicParseHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import { throttle } from '#utils/throttle';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { useScrollPosition } from '#hooks/use-scroll-position';
import { useWindowSize } from '#hooks/use-window-size';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

const staticQuery = graphql`
	query {
		allPrismicNavigation {
			nodes {
				data {
					main_items {
						label {
						  text
						}
						link {
						  url
						}
					}
					items {
						label {
							text
						}
						item {
							document {
								... on PrismicNavigationitem {
									tags
									data {
										link_items {
											link {
												url
												document {
													... on PrismicPage {
														data {
													  		body {
																... on PrismicPageDataBodySplash {
														  			primary {
																		title1 {
																			text
																		}
																		subtitle {
																			text
																		}
														  			}
																}
													  		}
														}
												  	}
												}
											}
										}
										card_link {
											document {
												... on PrismicBlogpage {
													tags
													url
													data {
													  	title {
															text
													  	}
													  	content {
															text
													  	}
													  	image {
															gatsbyImageData(
																placeholder: BLURRED
															)
														}
													}
												}
												... on PrismicPage {
													tags
													url
													data {
												  		body {
															... on PrismicPageDataBodySplash {
													  			id
													  			primary {
																	subtitle {
																		text
																	}
																	image {
																		gatsbyImageData(
																			placeholder: BLURRED
																		)
																	}
																	title1 {
																		text
																	}
													  			}
															}
												  		}
													}
											  	}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const Nav = (props) => {
	const { mode } = props;
	const divRefs = useRef([]);
	const staticData = useStaticQuery(staticQuery);
	const { data } = useMergePrismicPreviewData(staticData);
	
	const [open, setOpen] = useState(false);
	const [isShown, setIsShown] = useState(false);
	const [active, setActive] = useState(0);

	const scrollDirection = useScrollDirection();
	const { height: windowHeight } = useWindowSize();
	const scrollPosition = useScrollPosition();

	useEffect(() => {
		if (scrollDirection === DIRECTION.DOWN) {
			setIsShown(false);
		}
	}, [scrollDirection]);

	const conditionScrollPoint = scrollPosition > windowHeight + 180;
	const conditionShowNavMenu = conditionScrollPoint;

	const theMode = conditionScrollPoint || mode === MODE.DARK ? MODE.DARK : MODE.WHITE;
	const handleThrottledShown = throttle((val) => setIsShown(val), 350);

	const containerClasses = classNames(
		styles.cmtNav,
		{
			[styles.cmtNavNotFixedNav]: !conditionScrollPoint,
			[styles.cmtNavFixedNav]: conditionScrollPoint,
			[styles.cmtNavScrollNavDown]: conditionShowNavMenu && scrollDirection === DIRECTION.DOWN,
			[styles.cmtNavScrollUp]: conditionShowNavMenu && scrollDirection === DIRECTION.UP,
			[styles.cmtNavMegaShown]: isShown
		}
	);

	const navigation = getFirst(getObjectData(data, 'allPrismicNavigation.nodes'));
	const navItems = getObjectData(navigation, 'data.items');
	const navMainItems = getObjectData(navigation, 'data.main_items');

	const createdNavItems = useMemo(() => {
		return navItems.map(x => {
			const tag = getFirst(getObjectData(x, 'item.document.tags'));
			const linkDocumentData = getObjectData(x, 'item.document.data.card_link.document');
			const nestedData = getNestedDocumentData(linkDocumentData);
		
			return {
				tag: tag,
				items: x.item.document.data.link_items.map(y => {
					const document = getObjectData(y, 'link.document');
					if (document && document.data) {
					const url = getObjectData(y, 'link.url');
					const item = y.link.document.data.body.filter(obj => Object.keys(obj).length !== 0)[0];
					return {
						title: getObjectData(item, 'primary.title1.text'),
						subtitle: getObjectData(item, 'primary.subtitle.text'),
						url: url
					};
					}
				}),
				card: nestedData
			};
		});
	}, [navItems]);

	const openCb = useCallback(() => setOpen(true), []);
	const closeCb = useCallback(() => setOpen(false), []);

	return (
		<ModeProvider mode={theMode}>
			<nav className={containerClasses}>
				<NavDrawer
					open={open}
					onHamburger={open ? closeCb : openCb}
					scrolled={conditionScrollPoint}
					mainItems={
						navMainItems.map((x, i) => {
							const label = x.label.text;
							const url = x.link.url;
							return <DrawerButton key={i} size={DrawerButton.SIZE.LARGE} to={url}>{label}</DrawerButton>;
						})
					}
				>
					{
						createdNavItems.map((x, i) => {
							return (
								<DrawerAccordian key={i} title={x.tag}>
									{ x.items.map((y, i) => <DrawerButton key={i} to={y.url}>{y.title}</DrawerButton>) }
								</DrawerAccordian>
							);
						})
					}
				</NavDrawer>
				<SliceContainer className={styles.cmtNav__wrapper} containerClassName={styles.cmtNav__wrapperTwo}>
					<NavMenu
						open={open}
						minimal={conditionScrollPoint}
						fixedNav={conditionScrollPoint}
						expanded={isShown}
						onHamburger={open ? closeCb : openCb}
						onNavMouseOver={() => handleThrottledShown(true)}
						onNavMouseOut={() => handleThrottledShown(false)}
					>
						{
							createdNavItems.map((x, i) => {
								return <NavItem key={i} onMouseEnter={() => setActive(prevState => ({ prevValue: prevState.value, value: i }))}>{x.tag}</NavItem>;
							})
						}
					</NavMenu>
					<NavMega open={isShown} style={{ height: divRefs.current[active.value] ? divRefs.current[active.value].clientHeight : 0 }}>
						{
							createdNavItems.map((x, i) => {
								const items = x.items;
								const card = x.card;

								return (
									<NavMegaContainer
										key={i}
										onMouseOver={() => handleThrottledShown(true)}
										onMouseOut={() => handleThrottledShown(false)}
										active={active.value === i}
										prevActive={active.prevValue === i}
										slideFrom={active.prevValue > active.value ? 'left' : 'right'}
										ref={el => divRefs.current[i] = el}
										card={card ? <NavMegaCard {...card} /> : null}
										{...props}
									>
										{
											items.map((x, _i) => {
												return <NavMegaItem key={_i} to={x.url} title={x.title}>{x.subtitle}</NavMegaItem>;
											})
										}
									</NavMegaContainer>
								);
							})
						}
					</NavMega>
				</SliceContainer>
			</nav>
		</ModeProvider>
	);
};

Nav.MODE = MODE;
export default Nav;