// extracted by mini-css-extract-plugin
export var cmtFooter = "styles-module--cmt-footer--b1d23";
export var cmtFooter__address = "styles-module--cmt-footer__address--08ee4";
export var cmtFooter__addressContainer = "styles-module--cmt-footer__address-container--31bb7";
export var cmtFooter__addressDetails = "styles-module--cmt-footer__address-details--263ff";
export var cmtFooter__addressItem = "styles-module--cmt-footer__address-item--54192";
export var cmtFooter__addressTitle = "styles-module--cmt-footer__address-title--7dd32";
export var cmtFooter__addressWrapper = "styles-module--cmt-footer__address-wrapper--a0d7a";
export var cmtFooter__column = "styles-module--cmt-footer__column--09757";
export var cmtFooter__copyright = "styles-module--cmt-footer__copyright--285f2";
export var cmtFooter__copyrightText = "styles-module--cmt-footer__copyright-text--83e9f";
export var cmtFooter__link = "styles-module--cmt-footer__link--321d9";
export var cmtFooter__linkWrapper = "styles-module--cmt-footer__link-wrapper--6f069";
export var cmtFooter__links = "styles-module--cmt-footer__links--3b691";
export var cmtFooter__linksContainer = "styles-module--cmt-footer__links-container--aaa0c";
export var cmtFooter__linksWrapper = "styles-module--cmt-footer__links-wrapper--1f5ee";
export var cmtFooter__lockup = "styles-module--cmt-footer__lockup--71a9d";
export var cmtFooter__mark = "styles-module--cmt-footer__mark--e7a5f";
export var cmtFooter__sliceContainer = "styles-module--cmt-footer__slice-container--883ad";
export var cmtFooter__social = "styles-module--cmt-footer__social--a863b";
export var cmtFooter__socialItem = "styles-module--cmt-footer__social-item--d7157";