import React, { useCallback, useEffect, useRef, useState } from 'react';

import { LinearLoader } from '#components/linear-loader';
import { Loader } from '#components/loader';
import { RotatingWords } from './components/rotator';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { Typography } from '#components/typography';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql } from 'gatsby';
import CarouselButton from './components/carousel-button';
import Lottie from 'lottie-react';
import YouTube from 'react-youtube';
import classNames from 'classnames';
import loaderAnimation from '#lottie/loader-white.json';
import topImage from './top-image.jpg';

import * as styles from './styles.module.scss';

let origin;
if (typeof document !== 'undefined') {
    origin = window.location.origin;
};

const VIDEO_ONE = 'NE2-Y8LQt0s';
const VIDEO_TWO = 'F6naLdc7d-A';

const YT_OPTIONS = {
    height: '360',
    width: '640',
    frameborder: 0,
    playerVars: {
        modestbranding: 1,
        origin: origin,
        autoplay: 1,
        mute: 1,
        rel: 0,
        // eslint-disable-next-line camelcase
        widget_referrer: origin
    }
};

export const HomeSplash = (props) => {
    const { slice, className } = props;
    const { primary, items } = slice;
    const [playerIntro, setPlayerIntro] = useState(null);
    const [playerJourney, setPlayerJourney] = useState(null);
    const [durationPoint, setDurationPoint] = useState(0);
    const [count, setCount] = useState(0);
    const [introLoaded, setIntroLoaded] = useState(false);
    const [introFinished, setIntroFinished] = useState(false);
    const [loaderFinished, setLoaderFinished] = useState(false);
    const [fullLoadComplete, setFullLoadComplete] = useState(false);

    

    const lottieRef = useRef();
    
    const subtitle = getObjectData(primary, 'subtitle.text');

    const linkItems = items.map(x => {
        const word = x.word.text.toLowerCase();
        const tag = x.link.tags[0];
        const title = x.link.document.data.body.filter(obj => Object.keys(obj).length !== 0)[0].primary.title1.text;
        const url = x.link.url;

        return { word, tag, title, url };
    });

    const handlePlayerReady = useCallback((event) => {
        event.target.playVideo();
        setDurationPoint(event.target.getDuration()/5);
        setPlayerJourney(event.target);
    }, []);

    const handleIntroPlayerReady = useCallback((event) => {
        event.target.playVideo();
        setPlayerIntro(event.target);
    }, []);

    const handleOnStateChange = useCallback((event) => {
        setInterval(() => {
            const currentTime = playerJourney.getCurrentTime();
            if (currentTime > 31) {
                playerJourney.seekTo(0);   
            }
          }, 100);
      
        if (event.data === 0) {
          playerJourney.seekTo(0);
        } 
      }, [playerJourney]);

      useEffect(() => {
        console.log('fdsafdsafs');
        const handleVisibilityChange = () => {
            if (introFinished) {
                if (document.visibilityState === 'visible') {
                    console.log('1');
                    playerJourney.playVideo();
                } else {
                    console.log('2');
                    playerJourney.pauseVideo();
                }
            }
        };
    
        const handleWindowFocus = () => {
            if (introFinished) {
                console.log('11');
                playerJourney.playVideo();
            }
        };
    
        const handleWindowBlur = () => {
            if (introFinished) {
                console.log('22');
                playerJourney.pauseVideo();
            }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleWindowFocus);
        window.addEventListener('blur', handleWindowBlur);
    
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleWindowFocus);
            window.removeEventListener('blur', handleWindowBlur);
        };
    }, []);

    useEffect(() => {
        if (fullLoadComplete) {
            playerIntro.seekTo(0);
            playerIntro.playVideo();

            // this is to avoid a black flash
            setTimeout(() => {
                playerJourney.seekTo(0);
                setIntroFinished(true);
            }, 3750);
        }
    }, [fullLoadComplete]);

    useEffect(() => {
        if (introFinished) {
            const intervalId = setInterval(() => {
                if (count === 4) {
                    setCount(0);
                } else {
                    setCount(count + 1);
                }
            }, durationPoint*1000);

            return () => clearInterval(intervalId);
        }
    }, [count, introFinished]);

    const handleIntroOnStateChange = useCallback((event) => {
        if (event.data === 0) {
            setIntroLoaded(true);
        }
    }, []);

    const classes = classNames(
		styles.cmtSHomeSplash,
        {
            [styles.cmtSHomeSplashIntroFinished]: introFinished
        },
        className
    );

    const carouselItem = linkItems[count];

    const onComplete = useCallback(() => {
        if (introLoaded) {
            setFullLoadComplete(true);
        }
    }, [introLoaded]);

    useEffect(() => {
        if (lottieRef.current) {
            lottieRef.current.pause();

            if (loaderFinished) {
                setTimeout(() => {
                    lottieRef.current.play();
                }, 1500);
            }
        }
    }, [loaderFinished]);

    const placeholderClasses = classNames(
        styles.cmtSHomeSplash__videoPlaceholder,
        {
            [styles.cmtSHomeSplash__videoPlaceholderHide]: fullLoadComplete
        }
    );

    const lottieClasses = classNames(
        styles.cmtSHomeSplash__lottie,
        {
            [styles.cmtSHomeSplash__lottieShow]: loaderFinished
        }
    );

    const videoOneClasses = classNames(
        styles.cmtSHomeSplash__videoContainer,
        styles.cmtSHomeSplash__videoOne
    );

    return (
        <Slice className={classes} containerClassName={styles.cmtSHomeSplash__sliceInner}>
            { !introFinished && <Loader onLoadComplete={() => setLoaderFinished(true)} /> }
            <div className={placeholderClasses} style={{ backgroundImage: `url(${topImage})`, zIndex: 9999 }}>
                <Lottie lottieRef={lottieRef} animationData={loaderAnimation} loop={true} className={lottieClasses} onLoopComplete={onComplete}  />
            </div>
            {
                loaderFinished && (
                    <>
                        <div className={videoOneClasses}>
                            {
                                <YouTube
                                    iframeClassName={styles.cmtSHomeSplash__embed}
                                    videoId={VIDEO_ONE}
                                    opts={YT_OPTIONS}
                                    onReady={handleIntroPlayerReady}
                                    onStateChange={handleIntroOnStateChange}
                                />
                            }
                        </div>
                        <div className={styles.cmtSHomeSplash__embedContainer}>
                            {
                                <YouTube
                                    iframeClassName={styles.cmtSHomeSplash__embed}
                                    videoId={VIDEO_TWO}
                                    opts={YT_OPTIONS}
                                    onReady={handlePlayerReady}
                                    onStateChange={handleOnStateChange}
                                />
                            }
                        </div>
                    </>
                )
            }
            <SliceContainer className={styles.cmtSHomeSplash__sliceContainer}>
                <SliceColumn col className={styles.cmtSHomeSplash__sliceColumn}>
                    {
                        <div className={styles.cmtSHomeSplash__innerContainer}>
                            <div className={styles.cmtSHomeSplash__hero}>
                            <span className={styles.cmtSHomeSplash__title}>Trusted access</span>
                                <div style={{ display: 'flex' }}>
                                    <span className={styles.cmtSHomeSplash__title}>to&nbsp;</span>
                                    <div style={{ display: 'flex', position: 'relative', width: '100%' }}>
                                        { 
                                            introFinished && linkItems.map((x, i) => {
                                                return (
                                                    <RotatingWords key={i} duration={durationPoint} start={i === count} id={i}>{x.word}</RotatingWords>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.cmtSHomeSplash__toolbar}>
                                <Typography variant={Typography.VARIANT.B2} style={{ color: 'white' }}>{subtitle}</Typography>
                                <div className={styles.cmtSHomeSplash__loader}>
                                    <LinearLoader variant={LinearLoader.VARIANT.DETERMINATE} value={(count + 1) * 20} />
                                </div>
                                <div className={styles.cmtSHomeSplash__carousel}>
                                    <CarouselButton category={carouselItem.tag} title={carouselItem.title} url={carouselItem.url} />
                                </div>
                            </div>
                        </div>
                    }
                </SliceColumn>
            </SliceContainer>
        </Slice>
    );
};

export const query = graphql`
	fragment FragHomesplash on PrismicPageDataBodyHomesplash {
        id
        primary {
            subtitle {
                text
            }
        }
        items {
            word {
                text
            }
            link {
                url
                document {
                    ... on PrismicPage {
                        id
                        data {
                            body {
                                ... on PrismicPageDataBodySplash {
                                    primary {
                                        title1 {
                                            text
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                tags
            }
        }
	}
`;