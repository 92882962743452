import * as styles from './styles.module.scss';
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import gsap from 'gsap';

export const RotatingWords = ({ children, duration, start, id }) => {
  const containerRef = useRef(null);
  const containerClasses = classNames(styles.cmtNewText);
  const lineMaxLen = 15;
  const wsLookup = 15;
  const regex = new RegExp(String.raw`\s*(?:(\S{${lineMaxLen}})|([\s\S]{${lineMaxLen - wsLookup},${lineMaxLen}})(?!\S))`, 'g');
  const text = children
    .replace(regex, (_, x, y) => (x ? `${x}-\n` : `${y}\n`))
    .split(/\r?\n/)
    .filter((e) => e);

    useEffect(() => {
      const tl = gsap.timeline({
        onStart: () => {},
        onComplete: () => {
          if (containerRef.current) {
            containerRef.current.style.display = 'none';
          }
        }
      });
    
      tl.from(`.text-${id}`, {
        ease: 'power4.easeIn',
        y: '100%',
        delay: 0,
        skewY: 0.3,
        stagger: { amount: 0.29 }
      }).to(`.text-${id}`, {
        ease: 'power4.easeOut',
        y: '-100%',
        delay: duration - 1.8,
        skewY: 0.3,
        stagger: { amount: 0.29 }
      });
      
      if (start) {
        containerRef.current.style.display = 'flex';
        tl.play();
      } else {
        containerRef.current.style.display = 'none';
        tl.pause();
      }
    }, [duration, id, start]);
    
  const Letter = ({ space, letter }) => (
    <div className={`text text-${id}`}>{space ? '\u00A0' : letter}</div>
  );

  return (
    <div ref={containerRef} className={containerClasses}>
      {text.map((x, i) => (
        <div
          key={i}
          className={styles.cmtNewText__word}
        >
          {x.split('').map((y, _i) => (
            <Letter key={_i} space={y === ' '} letter={y} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default RotatingWords;
