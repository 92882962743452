import React, { useState } from 'react';

import { Card, CardActions, CardContent, CardHeader, CardMedia, CardText, CardWrapper } from '#components/card';
import { CardHorizontal } from '#slices/card-horizontal';
import { IconButton } from '#components/icon-button';
import { TYPE } from './constants';
import { getFirst, getObjectData } from '#utils/prismicParseHelpers';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import CollapseIcon from '#icons/arrow-right';

const blogStaticQuery = graphql`
	query BlogQuery {
		news: allPrismicBlogpage(limit: 5 sort: {order: DESC, fields: first_publication_date}) {
			edges {
				node {
					url
					tags
					first_publication_date(formatString: "DD MMMM yyyy")
					data {
						image {
							gatsbyImageData(
								placeholder: BLURRED
							)
						}
						title {
							text
						}
						content {
							text
						}
					}
				}
			}
		}
		foundation: allPrismicBlogpage(limit: 5 sort: {order: DESC, fields: first_publication_date} filter: {tags: {eq: "Foundation"}}) {
			edges {
				node {
					url
					tags
					first_publication_date(formatString: "DD MMMM yyyy")
					data {
						image {
							gatsbyImageData(
								placeholder: BLURRED
							)
						}
						title {
							text
						}
						content {
							text
						}
					}
				}
			}
		}
	}
`;

export const CardPopulatedSlice = ({ slice }) => {
	const [hoveredIndex, setHoveredIndex] = useState();

	const staticData = useStaticQuery(blogStaticQuery);
	const { data: { news, foundation } } = useMergePrismicPreviewData(staticData);
	
	const { primary } = slice;
	const color = getObjectData(primary, 'color1');
	const overline = getObjectData(primary, 'overline.text');
	const title = getObjectData(primary, 'title1.text');
	const type = getObjectData(primary, 'type1');

	let items;
	if (type === TYPE.LATEST) {
		items = news.edges;
	}
	
	if (type === TYPE.FOUNDATION) {
		items = foundation.edges;
	}

	return (
		<CardHorizontal color={color} title={title} overline={overline}>
		{
			items.map((x, i) => {
				const image = getImage(getObjectData(x, 'node.data.image.gatsbyImageData'));
				const title = getObjectData(x, 'node.data.title.text');
				const content = getObjectData(x, 'node.data.content.text');
				const date = getObjectData(x, 'node.first_publication_date');
				const category = getFirst(getObjectData(x, 'node.tags'));
				const url = getObjectData(x, 'node.url');

				return (
					<CardWrapper key={i}>
                        <Card to={url} onHover={() => setHoveredIndex(i)} style={{ height: 584 }}>
                            <CardMedia label={category} image={image} />
                            <CardContent>
                                <CardHeader subtitle={date}>{title}</CardHeader>
                                { content && <CardText>{content}</CardText> }
								<CardActions>
									<IconButton selected={hoveredIndex === i} position={IconButton.POSITION.RIGHT} icon={<CollapseIcon />}>Read more</IconButton>
								</CardActions>
                            </CardContent>
                        </Card>
					</CardWrapper>
				);
			})
		}
		</CardHorizontal>
	);
};

export const query = graphql`
	fragment FragCardpopulated on PrismicPageDataBodyCardpopulated {
		primary {
			type1
			color1
			overline {
				text
			}
			title1 {
				text
			}
		}
	}
`;

