import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const CardWrapper = (props) => {
	const { className, children, ...rest } = props;

	const classes = classNames(
		styles.cmtCardWrapper,
		className
	);

	return (
		<div className={classes} {...rest}>
            {children}
        </div>
	);
};

export default CardWrapper;
