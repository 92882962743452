import { InView } from 'react-intersection-observer';
import React, { createContext, useCallback, useContext, useState } from 'react';

export const Observer = (props) => {
	const { children, ...rest } = props;
	return <InView {...rest}>{children}</InView>;
};

export const ObserverContext = createContext({});

export const ObserverProvider = (props) => {
	const { onChange, ...rest } = props;
	const [inView, setInView] = useState(false);

	const wrappedOnChange = useCallback(
		intersecting => {
			setInView(intersecting);
			onChange(intersecting);
		},
		[onChange]
	);

	return (
		<ObserverContext.Provider value={{ inView }}>
			<Observer {...rest} onChange={wrappedOnChange}></Observer>
		</ObserverContext.Provider>
	);
};

export const useInView = () => {
	return !!useContext(ObserverContext).inView;
};
