import React from 'react';
import classNames from 'classnames';

import { MODE, useMode } from '#providers/mode';

import * as styles from './styles.module.scss';

export const Hamburger = (props) => {
	const { open, className, fixedNav, ...rest } = props;
    const mode = useMode();

	const containerClasses = classNames(
		styles.cmtHamburger,
        {
            [styles.cmtHamburgerOpen]: open,
            [styles.cmtHamburgerFixedNav]: fixedNav,
            [styles.cmtHamburgerModeDark]: mode === MODE.DARK
        },
        className
	);

	return (
        <div className={containerClasses} role='button' tabIndex={0} {...rest}>
            <div className={styles.cmtHamburger__lineOne} />
            <div className={styles.cmtHamburger__lineTwo} />
        </div>
	);
};

export default Hamburger;