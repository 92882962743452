import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { Parallax } from 'react-scroll-parallax';
import { Pill } from '#components/pill';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { Typography } from '#components/typography';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const SimpleSplash = (props) => {
    const { title, category, date, image, className } = props;

	const classes = classNames(
		styles.cmtSSimpleSplash,
		className
	);
 
	return (  
        <Slice className={classes} containerClassName={styles.cmtSSimpleSplash__sliceInner}>
            <SliceContainer>
                <SliceColumn className={styles.cmtSSimpleSplash__sliceColumn} col>
                    <div>
                        <Pill>{category}</Pill>
                    </div>
                    <span className={styles.cmtSSimpleSplash__title}>{title}</span>
                    <Typography className={styles.cmtSSimpleSplash__date} variant={Typography.VARIANT.B2}>{date}</Typography>
                    {
                        image && (
                            <div className={styles.cmtSSimpleSplash__imageWrapper}>
                                <Parallax speed={6}>
                                    <GatsbyImage
                                        className={styles.cmtSSimpleSplash__image}
                                        image={image}
                                        alt="Splash Image"
                                    />
                                </Parallax>
                            </div>
                        )
                    }
                </SliceColumn>
            </SliceContainer>
        </Slice>
	);
};