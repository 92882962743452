import Context from '../tab-group/store';
import React, { useCallback, useContext, useEffect } from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const Tab = (props) => {
	const {
		className,
		name,
		label,
		children,
		showOverflow,
		...rest
	} = props;

	const { onChange, isSelected, getInner } = useContext(Context);

	const selected = isSelected(name);

	useEffect(() => {
		getInner(name, children);
	});

	const containerClasses = classNames(
		styles.cmtTab,
		{
			[styles.cmtTabSelected]: selected === true,
			[styles.cmtTabOverflow]: showOverflow
		},
		className
	);

	const handleClick = useCallback(() => {
		onChange(name);
	}, [name, onChange]);

	return (
		<li
			className={containerClasses}
			role='tab'
			tabIndex={0}
			onClick={() => handleClick()}
			{...rest}
		>
			<span className={styles.cmtTab__label}>{label}</span>
		</li>
	);
};

Tab.defaultProps = {
	showOverflow: false
};

export default Tab;
