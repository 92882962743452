import { TYPE } from './constants';
import React from 'react';

import { Linker } from '#components/linker';
import { ListItem, OrderedList, UnorderedList } from '#components/list';
import ReactDOMServer, { renderToString } from 'react-dom/server';
import Typography from '#components/typography';
import he from 'he';

import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const textComponents = (type = TYPE.CONTENT) => ({
	heading1: ({ children }) => (
		<Typography className={styles.cmtPrismicText__h1} variant={Typography.VARIANT.H1}>{children}</Typography>
	),
	heading2: ({ children }) => (
		<Typography className={styles.cmtPrismicText__h2} variant={Typography.VARIANT.H2}>{children}</Typography>
	),
	heading3: ({ children }) => (
		<Typography className={styles.cmtPrismicText__h3} variant={Typography.VARIANT.H3}>{children}</Typography>
	),
	heading4: ({ children }) => (
		<Typography className={styles.cmtPrismicText__h4} variant={Typography.VARIANT.H4}>{children}</Typography>
	),
	heading5: ({ children }) => (
		<Typography className={styles.cmtPrismicText__h5} variant={Typography.VARIANT.H5}>{children}</Typography>
	),
	heading6: ({ children }) => (
		<Typography className={styles.cmtPrismicText__h6} variant={Typography.VARIANT.H6}>{children}</Typography>
	),
	preformatted: ({ children }) => {
		if (type === TYPE.CONTENT) {
			return (
				<div className={styles.cmtPrismicText__blockquote}>
					<Typography className={styles.cmtPrismicText__blockquoteText} variant={Typography.VARIANT.H5}>{children}</Typography>
				</div>
			);	
		}

		if (type === TYPE.POLICY) {
			const encodedHtml = renderToString(children).replace(/<br\/>/g, '');
			const decodedHtml = he.decode(encodedHtml);

			const counterHTML = decodedHtml.replace(/counter="(\d+)"/g, (match, group1) => {
				return `style="counter-reset: lis ${group1};" start="${group1}"`;
			});

			const hasBulletType = /<ol[^>]*type="bullet"/.test(decodedHtml);

			const listClassName = hasBulletType ? styles.cmtPrismicText__deepOrderedListDisc : styles.cmtPrismicText__deepOrderedListDisc;
		
			const ListComponent = () => {
				return <div className={listClassName} dangerouslySetInnerHTML={{ __html: counterHTML }} />;
			};

			const sanitizedHtml = ReactDOMServer.renderToString(<ListComponent />);
			return (
				<div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
			);
		}
	},
	paragraph: ({ children }) => (
		<Typography className={styles.cmtPrismicText__paragraph} variant={Typography.VARIANT.B1}>
			{children}
		</Typography>
	),
	list: ({ children }) => <UnorderedList>{children}</UnorderedList>,
	oList: ({ children }) => <OrderedList>{children}</OrderedList>,
	listItem: ({ children }) => <ListItem>{children}</ListItem>,
	oListItem: ({ children }) => <ListItem>{children}</ListItem>,
	image: ({ node }) => {
		return (
			<div className={styles.cmtPrismicText__imageContainer}>
				<img className={styles.cmtPrismicText__image} alt={node.alt} src={node.url} />	
				<div className={styles.cmtPrismicText__imageCaptionContainer}>
					<Typography className={styles.cmtPrismicText__imageCaption} variant={Typography.VARIANT.B4}>{node.alt}</Typography>
				</div>
			</div>
		);
	},
	hyperlink: ({ node, children }) => {
		const target = node.data.target ? `target="${node.data.target}" rel="noopener"` : '';
		const link = node.data.url;

		return (
			<Linker target={target} to={link} inline>
				{children}
			</Linker>
		);
	}
});

textComponents.TYPE = TYPE;