import React, { useState } from 'react';

import { Card, CardActions, CardContent, CardHeader, CardMedia, CardText } from '#components/card';
import { IconButton } from '#components/icon-button';
import CollapseIcon from '#icons/arrow-right';

export const CardStackCard = ({ className, pill, image, title, color, subtitle, text, label, link }) => {
	const [hovered, setHovered] = useState(false);
	
	return (
		<Card className={className} color={color} to={link} onHover={(v) => setHovered(v)}>
			<CardMedia label={pill} image={image} />
			<CardContent>
				<CardHeader subtitle={subtitle}>{title}</CardHeader>
				{ text && <CardText>{text}</CardText> }
				{
					(link && label) && (
						<CardActions>
							<IconButton selected={hovered} position={IconButton.POSITION.RIGHT} icon={<CollapseIcon />}>{label}</IconButton>
						</CardActions>
					)
				}
			</CardContent>
		</Card>
	);
};
