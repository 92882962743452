// extracted by mini-css-extract-plugin
export var cmtSFormUpload = "styles-module--cmt-s-form-upload--b3d3f";
export var cmtSFormUploadDragging = "styles-module--cmt-s-form-upload--dragging--87995";
export var cmtSFormUploadError = "styles-module--cmt-s-form-upload--error--da079";
export var cmtSFormUploadReady = "styles-module--cmt-s-form-upload--ready--3db4d";
export var cmtSFormUpload__bottomBox = "styles-module--cmt-s-form-upload__bottom-box--05db6";
export var cmtSFormUpload__button = "styles-module--cmt-s-form-upload__button--f0f3b";
export var cmtSFormUpload__chip = "styles-module--cmt-s-form-upload__chip--0e643";
export var cmtSFormUpload__form = "styles-module--cmt-s-form-upload__form--7876a";
export var cmtSFormUpload__hiddenInput = "styles-module--cmt-s-form-upload__hidden-input--926ac";
export var cmtSFormUpload__input = "styles-module--cmt-s-form-upload__input--cc86e";
export var cmtSFormUpload__loader = "styles-module--cmt-s-form-upload__loader--30d98";
export var cmtSFormUpload__lottie = "styles-module--cmt-s-form-upload__lottie--4f40b";
export var cmtSFormUpload__message = "styles-module--cmt-s-form-upload__message--9b64d";
export var cmtSFormUpload__messageDot = "styles-module--cmt-s-form-upload__message-dot--71b2d";
export var cmtSFormUpload__messageDotError = "styles-module--cmt-s-form-upload__message-dot-error--bc0d3";
export var cmtSFormUpload__outer = "styles-module--cmt-s-form-upload__outer--e9312";
export var cmtSFormUpload__ready = "styles-module--cmt-s-form-upload__ready--faed1";
export var cmtSFormUpload__readyIcon = "styles-module--cmt-s-form-upload__ready-icon--8f340";
export var cmtSFormUpload__readyText = "styles-module--cmt-s-form-upload__ready-text--0026d";
export var cmtSFormUpload__readyTextWrapper = "styles-module--cmt-s-form-upload__ready-text-wrapper--44606";
export var cmtSFormUpload__resting = "styles-module--cmt-s-form-upload__resting--0436a";
export var cmtSFormUpload__restingLabel = "styles-module--cmt-s-form-upload__resting-label--1b4af";
export var cmtSFormUpload__restingText = "styles-module--cmt-s-form-upload__resting-text--175ed";
export var cmtSFormUpload__title = "styles-module--cmt-s-form-upload__title--12ec3";
export var drawCircle = "styles-module--draw-circle--e6699";